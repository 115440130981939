<div fxShow fxHide.lt-sm fxLayout="row">
    <div fxFlex fxLayout="column">
        <mat-card class="paymentblock" fxLayout="column">
            <div class="block-top">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">price_check</mat-icon>
                </div>
                <div class="title" translate>
                    PAYMENT.PAYMENT_SUMMARY
                </div>
            </div>
            <span class="eng_content2 greentextcontent" style="padding-top:10px" translate>
                PAYMENT.SUBTOTAL
            </span>
            <div fxFlex fxLayout="row" *ngFor="let student of selectedStudents" class="eng_content1">
                <span style="float: left;">
                    {{student.name}}
                </span>
                <span style="float: left;margin-left:auto;">
                    {{student.price | currency:'HKD'}}
                </span>
            </div>
            <div *ngIf="getTotal() && getDiscount()>0" style="margin-top: 20px;">
                <span style="float: left;" translate>
                    PAYMENT.SIBLING_DISCOUNT
                </span>
                <span style="float: right;">
                    -{{getDiscount() | currency:'HKD'}}
                </span>
            </div>
            <div *ngIf="getTotal() && getDiscount()>0">
                <span style="float: left; font-size: 10px;" class="poppinsR" translate>
                    PAYMENT.SIBLING_DISCOUNT_DESCRIPTION
                </span>
            </div>
            <mat-divider fxFlex style="margin-top:20px;margin-bottom:20px;border-top: 1px block solid;" [inset]="true"></mat-divider>
            <div fxFlex fxLayout="row">
                <div style="float: left;" translate>
                    PAYMENT.TOTAL
                </div>
                <div style="float: left;margin-left:auto;">
                    {{getTotal() | currency:'HKD'}}
                </div>
            </div>
        </mat-card>
        <mat-card class="paymentblock" fxLayout="column">
            <div class="block-top">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">paid</mat-icon>
                </div>
                <div class="title" translate>
                    PAYMENT.PAYMENT
                </div>
            </div>
            <span class="eng_content2 greentextcontent" style="color:red" translate>
                PAYMENT.PLEASE_SELECT_STUDENT(S)
            </span>
            <div fxLayout="column">
                <div fxFlex *ngFor="let student of students; index as i" (click)="selectStudent(student)">
                    <div fxLayout="column" [ngClass]="{'unselectstudent':  !(_.find(selectedStudents, student))}" style="position:relative;display: block !important; padding:10px;border-radius:10px;margin-top:15px; background-color:#1b9d77 ;border: #1b9d77 2px solid; color:white;">
                        <span class="eng_content2 studentcard" style="float:left;  color: black;">
                            {{student.name}}
                        </span>
                        <span class="poppinsR" style=" color: black;">
                            {{translate.currentLang=='en'?student.Term.name:student.Term.name_chinese}}
                        </span>
                        <span class="poppinsR studentcard" style="float:left; color: black;">
                            Route {{student.BusStop.Route.name}}
                        </span>
                        <span class="poppinsR studentcard" style="float:left;  color: black;">
                            {{translate.currentLang=='en'?student.BusStop.name:student.BusStop.name_chinese}}
                        </span>
                        <span class="eng_content2 studentcard" style="position:absolute;bottom:8px;right:10px;">
                            {{student.price | currency:'HKD'}}
                        </span>
                        <mat-icon *ngIf="_.find(selectedStudents, student)" style="position:absolute;top:5px;right:5px;color:white;background:#1b9d77;border-radius: 50%;">
                            check</mat-icon>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div fxFlex fxLayout="column" style="padding-bottom: 50px;">
        <div style="display:none;">
            <input #uploadFile type="file" name="slip" (change)="onFileSelect($event)" />
        </div>
        <mat-card class="paymentblock" fxLayout="column">
            <div class="block-top component-margin">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">account_balance</mat-icon>
                </div>
                <div class="title" translate>
                    PAYMENT.PAYMENT_METHODS
                </div>
            </div>
            <div style="font-size: 18px;font-family: poppinsB;" translate>
                PAYMENT.PAYMENT_METHODS_TODO
            </div>
            <mat-accordion>
                <mat-expansion-panel (click)="payment_method = 'Bank Transfer'" style="margin-bottom:10px;background:white" [ngClass]="{'selectedPayment': payment_method == 'Bank Transfer'}">
                    <mat-expansion-panel-header style="padding:10px 20px;">
                        <mat-panel-title class="payment-title" translate>
                            PAYMENT.BANK_TRANSFER
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p translate>PAYMENT.BANK_NAME</p>
                    <p translate>PAYMENT.BANK_NUMBER</p>
                </mat-expansion-panel>
                <mat-expansion-panel (click)="payment_method = 'FPS'" style="margin-bottom:10px" [ngClass]="{'selectedPayment': payment_method == 'FPS'}">
                    <mat-expansion-panel-header style="padding:10px 20px;">
                        <mat-panel-title class="payment-title" translate>
                            PAYMENT.FPS
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p translate>PAYMENT.COMPANY_NAME</p>
                    <p translate>PAYMENT.FPS_NUMBER</p>
                </mat-expansion-panel>
                <mat-expansion-panel (click)="payment_method = 'Cheque'" style="margin-bottom:10px" [ngClass]="{'selectedPayment': payment_method == 'Cheque'}">
                    <mat-expansion-panel-header style="padding:10px 20px;">
                        <mat-panel-title class="payment-title" translate>
                            PAYMENT.CHEQUE
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p translate>PAYMENT.CHEQUE_MAIL</p>
                    <p>Kong Shing Travel Limited</p>
                    <p>P.O. Box 407 Tai Po post office Tai Po N.T.</p>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card>
        <mat-card class="paymentblock" fxLayout="column">
            <div class="block-top">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">file_upload</mat-icon>
                </div>
                <div class="title" translate>
                    PAYMENT.UPLOAD_PAYMENT_SLIP
                </div>
            </div>
            <span class="poppinsR" translate>PAYMENT.UPLOAD_PAYMENT_SLIP_DES</span>
            <div *ngIf="files" style="margin-top: 10px;">
                <div *ngFor="let file of files" style="position: relative;">
                    <img style="width:100%" [src]="file.payment_slip_file | jwtImage | async" />
                    <mat-icon (click)="removeSlip(file.id)" class="remove-image">clear</mat-icon>
                </div>
            </div>
            <div fxFlex fxLayout="column">
                <button fxFlex (click)="goBack()" type="submit" mat-stroked-button color="primary" translate>{{'PAYMENT.BACK_BTN'|translate}}</button>
                <div class="content-margintop"></div>
                <button fxFlex (click)="uploadFile.click()" type="submit" mat-raised-button color="accent" [disabled]="selectedStudents.length==0" translate>{{'PAYMENT.UPLOAD_PAYMENT_SLIP_BTN'|translate}}</button>
                <div class="content-margintop"></div>
                <button fxFlex (click)="submit()" type="submit" mat-raised-button color="accent" [disabled]="!files.length || !selectedStudents.length" translate>{{'PAYMENT.CONFIRM_BTN'|translate}}</button>
            </div>
        </mat-card>
    </div>
</div>

<div fxHide fxShow.lt-sm fxLayout="column" *ngIf="step==1" style="margin-bottom: 250px;">
    <div fxFlex fxLayout="column">
        <mat-card class="paymentblock" fxLayout="column">
            <div class="block-top">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">paid</mat-icon>
                </div>
                <div class="title" translate>
                    PAYMENT.PAYMENT
                </div>
            </div>
            <span class="eng_content2 greentextcontent" style="color:red" translate>
                PAYMENT.PLEASE_SELECT_STUDENT(S)
            </span>
            <div>
                <div *ngFor="let student of students; index as i" (click)="selectStudent(student)"
                    style="display:inline-block !important;width: 50%; position:relative;">
                    <div fxLayout="column" [ngClass]="{'unselectstudent':  !(_.find(selectedStudents, student))}" style="position:relative;display: block !important; padding:5px;border-radius:10px;margin:10px 3px 0px 3px; background-color:#1b9d77 ;border: #1b9d77 2px solid; color:white;">
                        <span class="eng_content2 studentcard" style="float:left; font-size:14px; margin-top: 5px; color:black;">
                            {{student.name}}
                        </span>
                        <span style="font-size:10px; margin-top: 5px; color:black;">
                            {{translate.currentLang=='en'?student.Term.name:student.Term.name_chinese}}
                        </span>
                        <span class="poppinsR studentcard"
                            style="float:left; font-size: 12px; margin-top: 5px; color:black;">
                            Route {{student.BusStop.Route.name}}
                        </span>
                        <span class="poppinsR studentcard" style="float:left; font-size: 12px;color:black;">
                            {{translate.currentLang=='en'?student.BusStop.name:student.BusStop.name_chinese}}
                        </span>
                        <span class="eng_content2 studentcard" style="float:right; font-size: 14px; margin-top: 10px;">
                            {{student.price | currency:'HKD'}}
                        </span>
                        <!-- <mat-icon *ngIf="_.find(selectedStudents, student)" style="position:absolute;top:-5px;right:-5px;color:white;background:#1b9d77;border-radius: 50%;">check</mat-icon>   -->
                    </div>
                </div>
            </div>
        </mat-card>
        <div style="display:none;">
            <input #uploadFile type="file" name="slip" (change)="onFileSelect($event)" />
        </div>
        <div fxFlex fxLayout="column" style="position:fixed;top:auto;bottom:0px;left:0px;width:100%;padding:10px 20px 20px 20px; background-color: white; border-top: 2px green solid;">
            <span class="eng_content2 greentextcontent" translate>
                PAYMENT.SUBTOTAL
            </span>
            <div fxFlex fxLayout="row" *ngFor="let student of selectedStudents" class="eng_content1">
                <span style="float: left;">
                    {{student.name}}
                </span>
                <span style="margin-left:auto;">
                    {{student.price | currency:'HKD'}}
                </span>
            </div>
            <div fxFlex *ngIf="getTotal() && getDiscount()>0" style="margin-top: 10px;">
                <span translate>
                    PAYMENT.SIBLING_DISCOUNT
                </span>
                <span style="margin-left:auto; float: right;">
                    -{{getDiscount() | currency:'HKD'}}
                </span>
            </div>
            <div *ngIf="getTotal() && getDiscount()>0">
                <span style="float: left; font-size: 10px;" class="poppinsR" translate>
                    PAYMENT.SIBLING_DISCOUNT_DESCRIPTION
                </span>
            </div>
            <mat-divider fxFlex style="margin-top:5px;margin-bottom:5px;border-top: 1px block solid;" [inset]="true"></mat-divider>
            <div fxFlex fxLayout="row">
                <span style="float: left;" translate>
                    PAYMENT.TOTAL
                </span>
                <span style="float: left;margin-left:auto;">
                    {{getTotal() | currency:'HKD'}}
                </span>
            </div>
            <div fxFlex fxLayout="row" style="margin-top:10px;">
                <button (click)="goBack()" type="submit" mat-raised-button color="accent" style="width:40%;float:left;" translate>{{'PAYMENT.BACK_BTN'|translate}}</button>
                <button (click)="step=2" type="submit" mat-raised-button color="accent" style="width:40%;float:right;margin-left:auto;" [disabled]="selectedStudents.length==0" translate>{{'PAYMENT.NEXT_BTN'|translate}}</button>
            </div>
        </div>
    </div>
</div>
<div fxHide fxShow.lt-sm fxLayout="column" *ngIf="step==2">
    <div fxFlex fxLayout="column">
        <mat-card class="block" fxLayout="column" style="padding:50px 0px 20px 0px">
            <div class="block-top">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">file_upload</mat-icon>
                </div>
                <div class="title" translate>
                    PAYMENT.UPLOAD_PAYMENT_SLIP
                </div>
            </div>
            <span class="poppinsR" style="margin-left: 20px;" translate>PAYMENT.UPLOAD_PAYMENT_SLIP_DES</span>
            <div *ngFor="let file of files" style="position: relative;">
                <img style="width:100%" [src]="file.payment_slip_file | jwtImage | async" />
                <mat-icon (click)="removeSlip(file.id)" class="remove-image">clear</mat-icon>
            </div>
            <div fxFlex style="position:fixed;top:auto;bottom:0px;left:0px;width:100%;padding:10px 20px 20px 20px; background-color: white; z-index:5; border-top: 2px green solid;">
                <div style="display:none;">
                    <input #uploadFile type="file" name="slip" (change)="onFileSelect($event)" />
                </div>
                <button (click)="uploadFile.click()" type="submit" mat-raised-button color="accent" style="width:100%;" translate>{{'PAYMENT.UPLOAD_PAYMENT_SLIP_BTN'|translate}}</button>
                <button (click)="submit()" type="submit" mat-raised-button color="accent" style="width:100%;margin-top:10px;" [disabled]="!files.length || !selectedStudents.length" translate>{{'PAYMENT.CONFIRM_BTN'|translate}}</button>
                <button (click)="goBack()" type="submit" mat-stroked-button color="primary" style="width:100%;margin-top:10px;" translate>{{'PAYMENT.BACK_BTN'|translate}}</button>
            </div>
        </mat-card>
        <mat-card class="block" fxLayout="column" style="margin-bottom:150px; margin-top: 40px; padding:50px 10px 20px 10px">
            <div class="block-top">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">account_balance</mat-icon>
                </div>
                <div class="title" translate>
                    PAYMENT.PAYMENT_METHODS
                </div>
            </div>
            <mat-accordion>
                <mat-expansion-panel style="margin-bottom:10px" (click)="payment_method = 'Bank Transfer'" [ngClass]="{'selectedPayment': payment_method == 'Bank Transfer'}">
                    <mat-expansion-panel-header style="padding:10px 20px;">
                        <mat-panel-title class="payment-title" translate>
                            PAYMENT.BANK_TRANSFER
                        </mat-panel-title>
                        <!-- <mat-panel-description>
                      This is a summary of the content
                    </mat-panel-description> -->
                    </mat-expansion-panel-header>
                    <p translate>PAYMENT.BANK_NAME</p>
                    <p translate>PAYMENT.BANK_NUMBER</p>
                </mat-expansion-panel>
                <mat-expansion-panel style="margin-bottom:10px" (click)="payment_method = 'FPS'" [ngClass]="{'selectedPayment': payment_method == 'FPS'}">
                    <mat-expansion-panel-header style="padding:10px 20px;">
                        <mat-panel-title class="payment-title" translate>
                            PAYMENT.FPS
                        </mat-panel-title>
                        <!-- <mat-panel-description>
                      Currently I am 
                    </mat-panel-description> -->
                    </mat-expansion-panel-header>
                    <p translate>PAYMENT.COMPANY_NAME</p>
                    <p translate>PAYMENT.FPS_NUMBER</p>
                </mat-expansion-panel>
                <mat-expansion-panel style="margin-bottom:10px" (click)="payment_method = 'Cheque'" [ngClass]="{'selectedPayment': payment_method == 'Cheque'}">
                    <mat-expansion-panel-header style="padding:10px 20px;">
                        <mat-panel-title class="payment-title" translate>
                            PAYMENT.CHEQUE
                        </mat-panel-title>
                        <!-- <mat-panel-description>
                      Currently I am 
                    </mat-panel-description> -->
                    </mat-expansion-panel-header>
                    <p translate>PAYMENT.CHEQUE_MAIL </p>
                    <p>Kong Shing Travel Limited</p>
                    <p>P.O. Box 407 Tai Po post office Tai Po N.T.</p>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card>
    </div>
</div>