import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Notification } from './types';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) { 
  }

  sendVerificationCode(mobile: string, recaptcha: string): Observable<any> {
    return this.http.post<any>(environment.apiEndpoint + 'mobile-verification',{mobile: mobile, recaptcha: recaptcha})
      .pipe(
        tap(_ => console.log('sendVerificationCode'))
      );
  }

  verifyCode(mobile: string, code: string): Observable<any> {
    return this.http.post<any>(environment.apiEndpoint + 'mobile-code-verification',{mobile: mobile, code: code})
      .pipe(
        tap(_ => console.log('verifyCode'))
      );
  }

  checkUsername(username: string): Observable<any> {
    return this.http.get<any>(environment.apiEndpoint + 'check-username/'+username)
      .pipe(
        tap(_ => console.log('checkUsername'))
      );
  }

  memberRegistration(name: string, username: string, password: string, email: string, phone: string, code: string){
    return this.http.post<any>(environment.apiEndpoint + 'member-registration', {
      name: name,
      username: username,
      password: password,
      email: email,
      phone: phone,
      code: code
    })
      .pipe(
        tap(_ => console.log('memberRegistration'))
      );
  }

  resetPassword(phone: string, recaptcha: string): Observable<any> {
    return this.http.post<any>(environment.apiEndpoint + 'reset-password',{phone: phone, recaptcha: recaptcha})
      .pipe(
        tap(_ => console.log('forgotPassword'))
      );
  }

  getNews(): Observable<any> {
    // return this.http.get<News[]>('/webapi/news')
    return this.http.get<Notification[]>(environment.apiEndpoint + 'news')
      .pipe(
        tap(_ => console.log('getNews'))
      );
  }

  getCurrentSchools(): Observable<any> {
    return this.http.get<any>(environment.apiEndpoint + 'schools/current')
      .pipe(
        tap(_ => console.log('getCurrentSchools'))
      );
  }

  getNextSchools(): Observable<any> {
    return this.http.get<any>(environment.apiEndpoint + 'schools/next')
      .pipe(
        tap(_ => console.log('getNextSchools'))
      );
  }

  getAvailableTerms(): Observable<any> {
    // return this.http.get<News[]>('/webapi/news')
    return this.http.get<any>(environment.apiEndpoint + 'available-terms')
      .pipe(
        tap(_ => console.log('getAvailableTermss'))
      );
  }

  createStudent(name:string, mobile:string, grade: string, service: string, remarks: string, busstop_id: Number, term_id:Number): Observable<any>{
    return this.http.post<any>(environment.apiEndpoint + 'student',{
      name: name,
      mobile: mobile,
      grade: grade,
      service: service,
      remarks: remarks,
      busstop_id: busstop_id,
      termId: term_id
    })
      .pipe(
        tap(_ => console.log('createStudent'))
      );
  }

  continueStudent(name:string, mobile:string, grade: string, service: string, remarks: string, busstop_id: Number, term_id: Number, studentId: Number): Observable<any>{
    return this.http.post<any>(environment.apiEndpoint + 'student',{
      name: name,
      mobile: mobile,
      grade: grade,
      service: service,
      remarks: remarks,
      busstop_id: busstop_id,
      termId: term_id,
      studentId: studentId
    })
      .pipe(
        tap(_ => console.log('continueStudent'))
      );
  }

  editStudent(name:string, mobile:string, grade: string, remarks: string, studentId: Number): Observable<any>{
    return this.http.put<any>(environment.apiEndpoint + 'student',{
      name: name,
      mobile: mobile,
      grade: grade,
      remarks: remarks,
      studentId: studentId
    })
      .pipe(
        tap(_ => console.log('editStudent'))
      );
  }

  getStudents(){
    return this.http.get<any>(environment.apiEndpoint + 'students')
      .pipe(
        tap(_ => console.log('getStudents'))
      );
  }

  getStudent(studentId:number){
    return this.http.get<any>(environment.apiEndpoint + 'student/' + studentId)
      .pipe(
        tap(_ => console.log('getStudent'))
      );
  }

  getPendingStudents(){
    return this.http.get<any>(environment.apiEndpoint + 'pending-students')
      .pipe(
        tap(_ => console.log('getPendingStudentss'))
      );
  }

  createPaymentRecord(studentIds: number[], fileIds: number[],price: number, paymentMethod: string){
    return this.http.post<any>(environment.apiEndpoint + 'payment-record', {
      studentId: studentIds,
      fileIds: fileIds,
      price: price,
      paymentMethod: paymentMethod
    })
      .pipe(
        tap(_ => console.log('createPaymentRecord'))
      );
  }

  uploadPaymentSlip(formData: any){
    return this.http.post<any>(environment.apiEndpoint + 'payment-slip', formData)
      .pipe(
        tap(_ => console.log('uploadPaymentSlip'))
      );
  }

  removePaymentSlip(imageId: number){
    return this.http.delete<any>(environment.apiEndpoint + 'payment-slip/' + imageId)
      .pipe(
        tap(_ => console.log('removePaymentSlip'))
      );
  }

  updatePaymentRecord(paymentRecordId: any, studentIds: any[], fileIds: any[], price: number, paymentMethod: string){
    return this.http.put<any>(environment.apiEndpoint + 'payment-record/' + paymentRecordId, {studentIds: studentIds, fileIds: fileIds, price: price,
      paymentMethod: paymentMethod})
      .pipe(
        tap(_ => console.log('updatePaymentRecord'))
      );
  }

  getMemberProfile(){
    return this.http.get<any>(environment.apiEndpoint + 'profile')
      .pipe(
        tap(_ => console.log('getMemberProfile'))
      );
  }

  updateMemberProfile(name: string, email: string){//ids = 1,2,3 (format)
    return this.http.put<any>(environment.apiEndpoint + 'profile', {name:name, email: email})
      .pipe(
        tap(_ => console.log('updateMemberProfile'))
      );
  }

  updateMemberLanguage(language: string){//ids = 1,2,3 (format)
    return this.http.put<any>(environment.apiEndpoint + 'profile/language', {language_preference: language})
      .pipe(
        tap(_ => console.log('updateMemberLanguage'))
      );
  }

  updateMemberPassword(oldpassword:string, newpassword: string){
    return this.http.put<any>(environment.apiEndpoint + 'password', {oldpassword:oldpassword,newpassword:newpassword})
    .pipe(
      tap(_ => console.log('updateMemberPassword'))
    );
  }

  updateMemberPhone(phone: string){
    return this.http.put<any>(environment.apiEndpoint + 'phone', {phone:phone})
    .pipe(
      tap(_ => console.log('updateMemberPhone'))
    );
  }

  getNotifications(){
    return this.http.get<any>(environment.apiEndpoint + 'notifications')
    .pipe(
      tap(_ => console.log('getNotifications'))
    )
  }

  getNewestNotifications(){
    return this.http.get<any>(environment.apiEndpoint + 'notifications/newest')
    .pipe(
      tap(_ => console.log('getNotifications'))
    )
  }

  updateNotificationStatus(notificationId: Number, status: string){
    return this.http.put<any>(environment.apiEndpoint + 'notification/' + notificationId, {status: status})
    .pipe(
      tap(_ => console.log('updateNotificationStatus'))
    )
  }

  getLatestPaymentRecord(){
    return this.http.get<any>(environment.apiEndpoint + 'payment-record')
    .pipe(
      tap(_ => console.log('getLatestPaymentRecord'))
    )
  }

  getPaymentRecord(id:number){
    return this.http.get<any>(environment.apiEndpoint + 'payment-record/' + id)
    .pipe(
      tap(_ => console.log('getPaymentRecord'))
    )
  }

  getRouteTerms(){
    return this.http.get<any>(environment.apiEndpoint + 'route-terms')
    .pipe(
      tap(_ => console.log('getRouteTerms'))
    )
  }

  getPyamentHistory(){
    return this.http.get<any>(environment.apiEndpoint + 'payment-history')
    .pipe(
      tap(_ => console.log('getPyamentHistory'))
    )
  }

  getMaintenance(){
    return this.http.get<any>(environment.apiEndpoint + 'maintenance')
    .pipe(
      tap(_ => console.log('getMaintenance'))
    )
  }
}
