<!-- Desktop -->
<div fxLayout="column" class="whiteboard-mid">
    <div fxFlex fxLayout="column" >
        <mat-card class="block" fxLayout="column">
            <div class="block-top" style="margin-top: 10px;">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">history</mat-icon>
                </div>
                <div class="componenttitle" translate>
                    PAYMENT_HISTORY.PAYMENT_HISTORY
                </div>
            </div>
            <div style="margin-top: 60px;" class="poppinsR">
                <mat-chip-list aria-label="Fish selection">
                    <mat-chip color="accent" [selected]="filter=='All'" (click)="applyFilter('All')">{{ 'PAYMENT_HISTORY.ALL' | translate }}</mat-chip>
                    <mat-chip color="accent" [selected]="filter=='Approved'" (click)="applyFilter('Approved')">{{ 'PAYMENT_HISTORY.APPROVED' | translate }}</mat-chip>
                    <mat-chip color="accent" [selected]="filter=='Rejected'" (click)="applyFilter('Rejected')">{{ 'PAYMENT_HISTORY.REJECTED' | translate }}</mat-chip>
                </mat-chip-list>
            </div>
            <div *ngFor="let paymentRecord of paymentRecords" style="border-bottom: 1px solid gray;position:relative;padding-bottom:10px;" class="payment-history-card-style">
                <mat-chip *ngIf="paymentRecord.status=='Approved'" class="poppinsR" style="position:absolute;top:0;right:0; background-color:#1B9D77; color: white;">{{ 'PAYMENT_HISTORY.APPROVED' | translate }}</mat-chip>
                <mat-chip *ngIf="paymentRecord.status=='Rejected'" class="poppinsR" style="position:absolute;top:0;right:0; background-color:red; color:white;">{{ 'PAYMENT_HISTORY.REJECTED' | translate }}</mat-chip>
                <div class="poppinsR" style="font-weight: 600;">
                    {{moment(paymentRecord.createdAt).format('DD/MM/YYYY')}}
                </div>
                <div *ngFor="let student of paymentRecord.Students" class="poppinsR payment-description">
                    <div>
                        {{student.name}} {{translate.currentLang=='en'?student.Term.name:student.Term.name_chinese}}
                    </div>
                </div>
                <div style="font-size: 20px;" class="payment-description">
                    ${{paymentRecord.price}}
                </div>
                <button *ngIf="!paymentRecord.display" mat-raised-button (click)="paymentRecord.display = true" color="accent" style="width:100%;" class="payment-description">
                    {{ 'PAYMENT_HISTORY.DETAIL' | translate }}
                </button>
                <div *ngIf="paymentRecord.display" style="position: relative;">
                    <img *ngFor="let image of paymentRecord.PaymentSlips" style="width:100%" [src]="image.payment_slip_file | jwtImage | async" />
                </div>
            </div>
        </mat-card>
    </div>
</div>