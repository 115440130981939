<div fxLayout="column">
    <div fxFlex fxLayout="column" >
        <mat-card class="block" fxLayout="column">
            <div class="block-top" style="margin-top:10px;">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">directions_bus_filled</mat-icon>
                </div>
                <div class="componenttitle" translate>
                    ROUTE_LIST.ROUTE_LIST
                </div>
            </div>
            <mat-form-field *ngIf="terms" fxHide fxShow.lt-sm style="margin-top:30px;">
                <mat-label translate>ROUTE_LIST.SELECT_TERM</mat-label>
                <mat-select [(ngModel)]="currentTerm" (ngModelChange)="onChangeTerm($event)" [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let term of terms" [value]="term">
                    {{translate.currentLang=='en'?term.name:term.name_chinese}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="currentTerm && currentTerm.PDF.Schools" fxHide fxShow.lt-sm >
                <mat-label translate>ROUTE_LIST.SELECT_SCHOOL</mat-label>
                <mat-select [(ngModel)]="currentSchool" [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let school of currentTerm.PDF.Schools" [value]="school">
                    {{translate.currentLang=='en'?school.name:school.name_chinese}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="terms" fxShow fxHide.lt-sm style="margin-left: auto;width: 260px;margin-top:30px;">
                <mat-label translate>ROUTE_LIST.SELECT_TERM</mat-label>
                <mat-select [(ngModel)]="currentTerm" (ngModelChange)="onChangeTerm($event)" [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let term of terms" [value]="term">
                    {{translate.currentLang=='en'?term.name:term.name_chinese}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="currentTerm && currentTerm.PDF.Schools" fxShow fxHide.lt-sm  style="margin-left: auto;width: 260px;">
                <mat-label translate>ROUTE_LIST.SELECT_SCHOOL</mat-label>
                <mat-select [(ngModel)]="currentSchool" [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let school of currentTerm.PDF.Schools" [value]="school">
                    {{translate.currentLang=='en'?school.name:school.name_chinese}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="terms && currentTerm && currentSchool">
                <div style="margin-bottom:30px;">
                    <span class="routelist-schoolname">{{currentSchool.name}}</span>
                    <table class="tg" id="my-table">
                        <thead>
                            <tr>
                                <th class="tg-0lax" colspan="2" style="font-weight: bolder; font-size: 18px;">上/落時間</th>
                                <th class="tg-0pky" colspan="1" style="font-weight: bolder; font-size: 18px;">地點 Location</th>
                                <th class="tg-0pky" style="width: 100px;">Bus Fare by<br><span style="font-weight: bolder; font-style: italic; font-size: 18px;">month</span></th>
                                <th class="tg-0pky" style="width: 100px;">Bus Fare by<br><span style="font-weight: bolder; font-size: 18px; color:#1B9D77;">{{currentTerm.name.split('(')[0]}}</span></th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let route of currentSchool.Routes">
                            <tr>
                            <td class="tg-0pky" colspan="4" style="font-weight: bolder; font-size: 18px;">{{route.label}} ︰<br>ROUTE {{route.name}}</td>
                            <td class="tg-0pky" colspan="2"></td>
                            </tr>
                            <tr *ngFor="let busstop of route.BusStops">
                                <td class="tg-0pky" style="width: 100px;">{{busstop.pick_up_time}}</td>
                                <td class="tg-0pky" style="width: 100px;">{{busstop.drop_down_time}}</td>
                                <td class="tg-0pky">{{busstop.name}}<br>{{busstop.name_chinese}}</td>                                
                                <td class="tg-0pky">{{busstop.monthly_price}}</td>
                                <td class="tg-0pky">{{busstop.price}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-card>
    </div>
</div>