<mat-card class="whiteboard-mid" *ngIf="step==0">
  <div class="block">
    <div class="block-top">
        <div class="block-label">
            <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">add_circle</mat-icon>
        </div>
        <div class="componenttitle" translate *ngIf="getMode()!=='edit'" translate>
          STUDENT.T&C
        </div>
    </div>
    <div class="content">
      <div class="content" fxLayout="column" >
        <div fxFlex fxLayout="column" class="Steppercontent-margintop poppinsR" *ngIf="translate.currentLang=='en'">
          <p style="font-size: 14px;color: red;">In the event of any conflict or ambiguity between any provision of the English version of this Agreement and its corresponding Chinese version, the English version shall prevail.</p><br><br>
          Dear Parents/Guardians,<br><br>

          Thank you for using our bus service. Please note the reminders below.<br><br>

          <span style="text-decoration: underline; font-weight: bold;">Reminders to parents/guardians/students:<br></span>
          ● Parents need to register / re register their child in the system, on time, before the beginning of every term to help secure their place in the school bus. Failure to do so may result in losing the seat even though the child had a seat in the previous term.
          <br>● Students should arrive at the pick-up point at least five (5) minutes prior to the scheduled departure time.
          <br>● Students should immediately leave their classroom once class is dismissed at the end of the day so that they do not miss their bus.
          <br>● Students should follow the Code of Conduct when using the bus service, including but not limited to, the proper use of seatbelts, no food/drink onboard, no foul language, and no noise. 
          <br>● Students should be kind and respectful to everyone on the bus. They should use respectful and appropriate language.
          <br>● Parents should understand that if the student continues to be involved in misconduct on the bus, they may not be able to use bus services.
          <br>● Please let the bus contact person know if the student has any prior arrangements for ECAs or requires a different drop-off point in the afternoon.
          <br>● Please note that the listed pick-up and drop-off points and times are for reference only.  After all reservations are made, minor adjustments to the pick-up/drop-off points and times may occur due to road conditions and route layout. Kong Shing has the final right to adjust the route.
          <br>● Refunds of the unused bus fee may be allowed case-by-case.  If allowed, the refund will be calculated proportionally with a month's notice before the termination of the bus service.  No partial-month refund is permitted.  In the case of a dispute, Kong Shing Travel Limited has sole discretion in making a final decision.  Please contact us for more details.
          <br>● If parents want to change routes or pick up/drop off points after registration, they must notify Kong Shing with a month’s notice before changes. It can only be arranged when there are vacancies in the replacement route.
          <br>● School Transport Safety Rules stated that the students should be obedient when taking school buses.  If the student’s behaviour influences others and interferes with the safety of the moving school bus, Kong Shing Travel Limited will terminate the bus service for them immediately.
          <br>● Students who have lost or left behind any belongings on the school bus, please report to us or the school reception on the same day.  We will make the best effort to retrieve the lost property; however, it is not guaranteed that all lost property items will be recovered.  The more details and information provided, the easier it is to retrieve the lost items.  All students should take care of their belongings when using the school bus.  Our company will not be liable for the missing or damaged students’ belongings.
          <br>● In situations when EDB announces school closure or face-to-face class suspension, special credit arrangements will be made on prepaid but unused bus fees.  If there are any remaining prepaid but unused school bus fees, they will be carried forward to the next payment.
          <br>a) If the announced suspension date is on or before the 10th, 50% of the month's bus fee will be credited.
          <br>b) If the announced suspension date is on or after the 11th, that month's bus fee will be fully charged without any credit.
          <br>c) When school is suspended for over a month, 50% of bus fees will be credited in each of the following months.
          <br>● A seat is guaranteed only if registration is successful, full payments are made within the registration period, and payment slips are uploaded. No seat is guaranteed if registration is unsuccessful and payments and/or payment slips are not uploaded.
          <br>● Failure to pay the full bus fee will lead to the suspension of the bus service until the payment is fully settled.
          <br>● In case of any disputes, Kong Shing Travel Limited's decision shall be final.
          
          <span style="text-decoration: underline; font-weight: bold;">For Sha Tin College parents/guardians/students:<br></span>
          Bus dismissal time for Sha Tin College:
          <br>● The school buses leave at 3:35 pm Monday - Friday (except Wednesday)
          <br>● On Wednesday buses leave at 1:35 pm
          <br>● The driver regularly checks bus passes.  Please ensure your child has their bus pass at all times.  Please print and bring along the SMS confirmation for new registrations until a bus pass is issued.
          <br>● Students are responsible for their bus pass. Reissuing a new bus pass due to damage or loss will result in a $50 handling fee.
          <br>● Payments can be made by cheque, via FPS, or via bank deposit. Cheques should be made payable to:
          <br>
          <h3 style="text-decoration: underline;">Kong Shing Travel Limited</h3>
          and mailed to:<br>
          <h3>P.O. Box 407, Tai Po Post Office, N.T., Hong Kong</h3>
          Please write the student name, year/class, contact number and bus route number/pickup point on the back of the cheque.<br><br>

          <span style="text-decoration: underline; font-weight: bold;">For Sha Tin Junior School parents/guardians/students:</span>
          Bus dismissal time for Sha Tin Junior School:<br>
          ● The school buses leave at 2:55 pm Monday - Friday<br>
          ● Payments can be made by cheque, via FPS, or via bank deposit. Cheques should be made payable to: <br>
          <h3 style="text-decoration: underline;">Kong Shing Travel Limited</h3>
          and mailed to:<br>
          <h3>P.O. Box 407, Tai Po Post Office, N.T., Hong Kong</h3>
          Please write the student name, year/class, contact number and bus route number/pickup point on the back of the cheque. Cheques can be handed over directly to the bus nanny or the school office.<br><br>

          We strive to provide students with the most safe and efficient journey to school. Please email us if you have any queries or require special arrangements or comments.<br><br>

          Yours faithfully,<br>
          Kong Shing Travel Limited:<br>
          Email: <a href="mailto:kongshing.mswan@yahoo.com">kongshing.mswan@yahoo.com</a><br><br>
          
          <mat-checkbox [(ngModel)]="agreeTerms"><div class="poppinsR" style="font-size: 12px; white-space: break-spaces;">{{'STUDENT.I_AGREE'|translate}}</div></mat-checkbox>
          <button mat-raised-button color="accent" (click)="step=1" class="Steppercontent-margintop" [disabled]="!agreeTerms">{{ 'STUDENT.UNDERSTOOD' | translate }}</button>
        </div>
        <div fxFlex fxLayout="column" class="Steppercontent-margintop poppinsR" *ngIf="translate.currentLang!='en'">
          <p style="font-size: 14px;">在本協議之條文之中英文版本有抵觸或歧異時，應以英文版本為準。</p><br>
          致 家長/監護人，<br><br>

          感謝使用本公司的校車服務。請留意以下事項。<br><br>

          ● 家長需要在每個學期開始前，按時限註冊/重新註冊，以幫助確保學生在校車上的座位。如果不按時限完成註冊，即使孩子在上學期有座位，也可能會失去座位。
          <br>● 學生應提前最少5分鐘到達接送點。
          <br>● 下課後學生應立即離開教室，以免錯過校車。
          <br>● 學生在使用校車服務時應遵守行為準則，包括但不限於正確使用安全帶、尊重他人；車上禁止飲食、禁止粗言穢語、以及禁止發出噪音。
          <br>● 學生應該善待和尊重校車上的每一個人。他們應該使用尊重和適當的語言。
          <br>● 家長應該明白，如果學生繼續在校車上有不當行為，他們可能無法使用校車服務。
          <br>● 如學生放學後有安排任何課外活動 (ECA)或下午需於不同地點下車，請提早通知校車聯繫人。
          <br>● 接送點和時間僅供參考。接送地點和時間可能會因路況和路線編排而有所調整。港誠旅遊有限公司保留調整路線之最終決定權。
          <br>● 如申請終止校車服務，須提前一個月通知。退款將根據具體情況處理，若獲批准退款，退款金額會按比例計算，不設部分月份退款。如有爭議，港誠旅遊有限公司擁有最終決定權。如欲了解詳情請與我們聯絡。
          <br>● 如果家長在報名後想更改路線或接送點，必須提前一個月通知。更改只能在替換路線有空位的情況下才能安排。
          <br>● “校車安全規則”規定學生在乘坐校車時必須遵守秩序。如學生的行為影響他人及影響正在行駛的校巴的安全，港誠旅遊有限公司將立即終止為他/ 她提供巴士服務以保障全體學生的安全。
          <br>● 如學生在校車上遺失或遺留任何物品，請於當日向本公司或學校接待處報告。本公司會盡最大努力協助，但不能保證找回所有失物。學生提供的細節越多，便越容易找回失物。學生在乘坐校車時應保管好自己的財物。個人財物若有遺失或損壞，本公司概不負責。
          <br>● 在教育局宣布基於疫情而停課的情況下，本公司將對已預繳但未使用的校車服務將作出特別安排。任何剩餘的預繳車費將撥入下一期作扣減之用。
          <br>a) 如宣布停運日期是在 10 號或之前，本公司將收取該月50%校車費用。
          <br>b) 如宣布的停運日期為11號或之後，本公司將收取該月車費全數，不予抵扣。
          <br>c) 在學校停課超過一個月的情況下，本公司將在接下來的每個月中收取 50% 的校車費用。
          <br>● 只有在成功登記並在報名限期內全額付款並上傳付款收據後，才可保證留位成功。如果登記不成功或未能上傳付款和/或付款收據則無法保證成功留位。
          <br>● 如未能繳付全額車費將被暫停校車接送服務，直至繳清費用為止。
          <br>● 如有任何爭議，港誠旅遊有限公司保留最終決定權。
          <br>
          <br>
          <span style="text-decoration: underline; font-weight: bold;">沙田學院下課校車時間：</span>
          <br>● 校車於星期一至星期五下午3:35離校(星期三除外)
          <br>● 星期三校車於下午1:35離校
          <br>● 司機會定期檢查校車乘車證。請確保學生攜帶校車乘車證。新註冊學生請攜帶校巴確認信，直到派發校車乘車證為止。
          <br>● 學生需自行保管校車乘車證。若由於損壞或丟失而需重新補發之新校車乘車證將收取 $50 補領費。
          <br>● 請以支票、轉數快或銀行轉帳付款。支票抬頭請寫：
          <br>
          <p style="text-decoration: underline;">Kong Shing Travel Limited</p>
          並郵寄至：<br>
          <p style=" font-weight: bold;">香港新界大埔郵政局郵政信箱407號</p>
          請在支票背面寫上學生姓名、年級/班級、聯絡電話和校車路線號碼/接送點。<br><br>

          <span style="text-decoration: underline; font-weight: bold;">沙田小學下課校車時間：</span>
          ● 校車於星期一至星期五下午2:55離校<br>
          ● 請以支票、轉數快或銀行轉帳付款。支票抬頭請寫：<br>
          <p style="text-decoration: underline;">Kong Shing Travel Limited</p>
          並郵寄至：<br>
          <p style="font-weight: bold;">香港新界大埔郵政局郵政信箱407號</p>
          請在支票背面寫上學生姓名、年級/班級、聯絡電話和巴士路線號碼/接送點。支票可直接交給校車保姆或校務處。<br><br>

          我們致力為學生提供最安全、最優質的旅程。如有任何疑問或需要特別安排或意見，歡迎電郵我們: <a href="mailto:kongshing.mswan@yahoo.com">kongshing.mswan@yahoo.com</a><br><br>

          港誠旅遊有限公司<br><br>
          
          <mat-checkbox [(ngModel)]="agreeTerms"><div class="poppinsR" style="font-size: 12px; white-space: break-spaces;">{{'STUDENT.I_AGREE'|translate}}</div></mat-checkbox>
          <button mat-raised-button color="accent" (click)="step=1" class="Steppercontent-margintop" [disabled]="!agreeTerms">{{ 'STUDENT.UNDERSTOOD' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</mat-card>
<mat-card *ngIf="step==1 && window.screen.width>=768"  class="whiteboard-mid">
  <div class="block">
    <div class="block-top">
        <div class="block-label">
            <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">add_circle</mat-icon>
        </div>
        <div class="componenttitle" translate *ngIf="getMode()!=='edit'" translate>
          STUDENT.CREATE_STUDENT
        </div>
        <div class="componenttitle" translate *ngIf="getMode()=='edit'" translate>
          STUDENT.EDIT_STUDENT
      </div>
    </div>
          
    <div class="content">
        <form [formGroup]="studentFormGroup" *ngIf="terms.length && (currentSchools.length||nextSchools.length)">
            <mat-form-field appearance="fill" style="width:100%" *ngIf="getMode()!=='edit'">
              <mat-label translate>STUDENT.FORM_SELECT_TERM</mat-label>
              <mat-select (selectionChange)="onChangeTerm()" [(ngModel)]="student.term" formControlName="term" >
                <mat-option *ngFor="let term of terms" [value]="term">
                  {{translate.currentLang=='en'?term.name:term.name_chinese}}(<span *ngIf="term.status=='Current'">{{'STUDENT.CURRENT'|translate}}</span><span *ngIf="term.status=='Next'">{{'STUDENT.NEXT'|translate}}</span>)
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width:100%">
                <mat-label translate>STUDENT.FORM_NAME</mat-label>
                <input matInput placeholder="{{'STUDENT.FORM_NAME'|translate}}" formControlName="name" required>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width:100%">
              <mat-label translate>STUDENT.MOBILE</mat-label>
              <input matInput (keypress)="numberOnly($event)" placeholder="{{'STUDENT.FORM_MOBILE'|translate}}" formControlName="mobile" required>
              <mat-error *ngIf="studentFormGroup.controls.mobile.invalid">{{'STUDENT.NUMBERS_ONLY'|translate}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width:100%" *ngIf="getMode()!=='edit'">
              <mat-label translate>STUDENT.FORM_SELECT_SCHOOL</mat-label>
              <mat-select [(ngModel)]="currentSchool" (ngModelChange)="onChangeSchool($event)" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let school of schools" [value]="school">
                  {{translate.currentLang=='en'?school.name:school.name_chinese}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width:100%" *ngIf="getMode()!=='edit'">
              <mat-label translate>STUDENT.FORM_GRADE</mat-label>
              <mat-select [(ngModel)]="student.grade" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let grade of grades" [value]="grade.grade">
                  {{translate.currentLang=='en'?grade.english:grade.chinese}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width:100%" *ngIf="getMode()!=='edit'">
              <mat-label translate>STUDENT.FORM_SELECT_SERVICE</mat-label>
              <mat-select [(ngModel)]="student.service" [ngModelOptions]="{standalone: true}">
                <mat-option class="poppinsR" *ngFor="let service of services" [value]="service.value">
                  {{translate.currentLang=='en'?service.name:service.name_chinese}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width:100%" *ngIf="getMode()!=='edit' && currentSchool.id && student.grade" 
            [ngStyle]="studentFormGroup.controls.route.errors?.J12?{'margin-bottom': '30px'} : {}">
                <mat-label translate>STUDENT.FORM_SELECT_ROUTE</mat-label>
                <mat-select [(ngModel)]="currentRoute" formControlName="route" (ngModelChange)="checkRouteLimit()">
                  <mat-option *ngFor="let route of rr()" [value]="route">
                    {{route.name}} {{route.label}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="studentFormGroup.controls.route.errors?.full">{{'STUDENT.ROUTE_FULL_ERROR'|translate}}</mat-error>
                <mat-error *ngIf="studentFormGroup.controls.route.errors?.J12">{{'STUDENT.ROUTE_ARRANGE_ERROR'|translate}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width:100%" *ngIf="getMode()!=='edit' && currentSchool.id && student.grade && currentRoute.id">
                <mat-label translate>STUDENT.FORM_SELECT_BUSSTOP</mat-label>
                <mat-select [(ngModel)]="student.busstop_id" formControlName="busstop">
                  <mat-option *ngFor="let busstop of currentRoute.BusStops" [value]="busstop.id">
                    {{translate.currentLang=='en'?busstop.name:busstop.name_chinese}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width:100%">
              <mat-label translate>STUDENT.FORM_REMARKS</mat-label>
              <input matInput placeholder="{{'STUDENT.FORM_REMARKS'|translate}}" formControlName="remarks">
            </mat-form-field>
            <span class="poppinsR Steppercontent-margintop" translate>STUDENT.FORM_REMARKS_CONTENT</span>
            <div class="Steppercontent-margintop">
              <button (click)="return()" mat-raised-button color="accent">{{'STUDENT.RETURN'|translate}}</button>
              <button *ngIf="getMode()=='edit'" style="float: right;" [disabled]='!studentFormGroup.controls.name.value ||!studentFormGroup.controls.mobile.value || studentFormGroup.controls.mobile.status!=="VALID" || submitting' (click)="submitForm()" mat-raised-button color="accent">{{'STUDENT.COMPLETE'|translate}}</button>
              <button *ngIf="getMode()!=='edit'" style="float: right;" [disabled]='!student.busstop_id || !studentFormGroup.controls.mobile.value || studentFormGroup.controls.mobile.status!=="VALID" || studentFormGroup.status=="INVALID" || !student.service || !studentFormGroup.controls.name.value || submitting' (click)="submitForm()" mat-raised-button color="accent">{{'STUDENT.COMPLETE'|translate}}</button>
            </div>
          </form>
      </div>
  </div>
</mat-card>
<mat-card *ngIf="step==1 && window.screen.width<768" >
  <div class="block">
      <div class="block-top">
          <div class="block-label">
              <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">info_outline</mat-icon>
          </div>
          <div class="componenttitle" translate *ngIf="getMode()!=='edit'" translate>
            STUDENT.CREATE_STUDENT
          </div>
          <div class="componenttitle" translate *ngIf="getMode()=='edit'" translate>
            STUDENT.EDIT_STUDENT
        </div>
      </div>
      <div class="content">
          <form [formGroup]="studentFormGroup" *ngIf="terms.length && (currentSchools.length||nextSchools.length)" class="mobileForm">
              <mat-form-field appearance="fill" style="width:100%" *ngIf="getMode()!=='edit'">
                <mat-label translate>STUDENT.FORM_SELECT_TERM</mat-label>
                <mat-select [(ngModel)]="student.term" formControlName="term">
                  <mat-option class="poppinsR" *ngFor="let term of terms" [value]="term">
                    {{translate.currentLang=='en'?term.name:term.name_chinese}}(<span *ngIf="term.status=='Current'">{{'STUDENT.CURRENT'|translate}}</span><span *ngIf="term.status=='Next'">{{'STUDENT.NEXT'|translate}}</span>)
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" style="width:100%">
                  <mat-label translate>STUDENT.FORM_NAME</mat-label>
                  <input matInput placeholder="{{'STUDENT.FORM_NAME'|translate}}" formControlName="name" required>
              </mat-form-field>
              <mat-form-field appearance="fill" style="width:100%">
                <mat-label translate>STUDENT.MOBILE</mat-label>
                <input matInput (keypress)="numberOnly($event)" placeholder="{{'STUDENT.FORM_MOBILE'|translate}}" formControlName="mobile" required>
                <mat-error *ngIf="studentFormGroup.controls.mobile.invalid">{{'STUDENT.NUMBERS_ONLY'|translate}}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" style="width:100%" *ngIf="getMode()!=='edit'">
                <mat-label translate>STUDENT.FORM_SELECT_SCHOOL</mat-label>
                <mat-select [(ngModel)]="currentSchool" (ngModelChange)="onChangeSchool($event)" [ngModelOptions]="{standalone: true}">
                  <mat-option class="poppinsR" *ngFor="let school of schools" [value]="school">
                    {{translate.currentLang=='en'?school.name:school.name_chinese}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" style="width:100%">
                <mat-label translate>STUDENT.FORM_GRADE</mat-label>
                <mat-select [(ngModel)]="student.grade" (ngModelChange)="onChangeSchool($event)" formControlName="grade">
                  <mat-option *ngFor="let grade of grades" [value]="grade.grade">
                    {{translate.currentLang=='en'?grade.english:grade.chinese}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" style="width:100%" *ngIf="getMode()!=='edit'">
                <mat-label translate>STUDENT.FORM_SELECT_SERVICE</mat-label>
                <mat-select [(ngModel)]="student.service" [ngModelOptions]="{standalone: true}">
                  <mat-option class="poppinsR" *ngFor="let service of services" [value]="service.value">
                    {{translate.currentLang=='en'?service.name:service.name_chinese}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" style="width:100%" *ngIf="getMode()!=='edit' && currentSchool.id && student.grade" [ngClass]="{'two-line-spacing': translate.currentLang=='en' && studentFormGroup.controls.route.status=='INVALID'}" 
              [ngStyle]="studentFormGroup.controls.route.errors?.J12?{'margin-bottom': '30px'} : {}">
                <mat-label translate>STUDENT.FORM_SELECT_ROUTE</mat-label>
                <mat-select [(ngModel)]="currentRoute" formControlName="route" (ngModelChange)="checkRouteLimit()">
                  <mat-option class="poppinsR" *ngFor="let route of rr()" [value]="route">
                    {{route.name}} {{route.label}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="studentFormGroup.controls.route.errors?.full">{{'STUDENT.ROUTE_FULL_ERROR'|translate}}</mat-error>
                <mat-error *ngIf="studentFormGroup.controls.route.errors?.J12">{{'STUDENT.ROUTE_ARRANGE_ERROR'|translate}}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" style="width:100%" *ngIf="getMode()!=='edit' && currentSchool.id && student.grade && currentRoute.id">
                <mat-label translate>STUDENT.FORM_SELECT_BUSSTOP</mat-label>
                <mat-select [(ngModel)]="student.busstop_id" formControlName="busstop">
                  <mat-option class="poppinsR" *ngFor="let busstop of currentRoute.BusStops" [value]="busstop.id">
                    {{translate.currentLang=='en'?busstop.name:busstop.name_chinese}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" style="width:100%">
                <mat-label translate>STUDENT.FORM_REMARKS</mat-label>
                <input matInput placeholder="{{'STUDENT.FORM_REMARKS'|translate}}" formControlName="remarks">
              </mat-form-field>
              <span class="poppinsR Steppercontent-margintop" translate>STUDENT.FORM_REMARKS_CONTENT</span>
              <div class="Steppercontent-margintop">
                <button (click)="return()" mat-raised-button color="accent">{{'STUDENT.RETURN'|translate}}</button>
                <button style="float: right;" [disabled]='studentFormGroup.status=="INVALID" || !student.busstop_id || !studentFormGroup.controls.name.value || !studentFormGroup.controls.mobile.value || studentFormGroup.controls.mobile.status!=="VALID" || !student.service || submitting' (click)="submitForm()" mat-raised-button color="accent">{{'STUDENT.COMPLETE'|translate}}</button>
              </div>
            </form>
      </div>
  </div>
</mat-card>