import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LoginService } from '../login.service'
import * as moment from 'moment'
import { ApiService } from '../api.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public router: Router,  private loginService: LoginService, private apiService: ApiService) {}

  async getValueFromObservable() {
    return new Promise(resolve=>{
      this.apiService.getMaintenance().subscribe(maintenance=>{
        resolve(maintenance);
      })
    })
  }

  async canActivate(): Promise<boolean> {
    let maintenance:any = await this.getValueFromObservable()
    if (maintenance.online == 'false'){
      this.loginService.logout()
      this.router.navigate(['/']);
      return false;
    }
    
   const token = localStorage.getItem('id_token');
   const expires_at = localStorage.getItem('expires_at');
    if (!token) {
      this.router.navigate(['/']);
      return false;
    }
    if(!moment().isBefore(moment(expires_at))){
      this.loginService.logout()
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}