import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators, AbstractControl, ValidatorFn} from '@angular/forms';

import { ApiService } from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

export interface School{
  id: Number;
  name: string;
  name_chinese: string;
  Routes: Route[];
  type: string;
}

export interface Route{
  id: number;
  name: string;
  name_chinese: string;
  BusStops: BusStop[];
  bus_type: string;
  min_grade: Number;
  student_limit: Number;
  currentStudentNo: Number;
}

export interface BusStop{
  id: Number;
  name: string;
  name_chinese: string;
}

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})

export class StudentComponent implements OnInit {
  schools:School[] = [];
  currentSchools:School[] = [];
  nextSchools:School[] = [];
  terms: any=[]
  currentSchool:School = <School>{};
  currentRoute:Route = <Route>{};
  window:any = window
  student:any = {
    term: {},
    name: "",
    grade: 1,
    remarks: "",
    busstop_id: null,
    mobile: "",
    service: null
  }
  studentFormGroup: FormGroup = <FormGroup>{}
  grades:any = []
  submitting:boolean = false;
  _ = _
  step = 2;
  additionCheck = false;
  agreeTerms: boolean = false;
  services: any=[
    {
      value: "Two Way",
      name: "Two Way",
      name_chinese: "雙程",
    },{
      value: "Pick Up",
      name: "One way AM Pick-Up Only",
      name_chinese: "單程去程",
    },{
      value: "Drop Down",
      name: "One way PM Drop-Off Only",
      name_chinese: "單程回程",
    }
  ]
  constructor(private apiService: ApiService,public translate: TranslateService, private router:Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
      if(window.location.pathname.split('/')[1]=='edit-student'){
        this.step = 1
      }else{
        this.step = 0
      }
      
      this.apiService.getCurrentSchools().subscribe(currentSchools=>{
        this.currentSchools = currentSchools;
        this.apiService.getNextSchools().subscribe(nextSchools=>{
          this.nextSchools = nextSchools;
          if(window.location.pathname.split('/')[1]=='continue-student'||window.location.pathname.split('/')[1]=='edit-student'){
            this.route.params.subscribe(params => {
              this.apiService.getStudent(params['studentId']).subscribe(res=>{
                this.student = res.student;
                this.studentFormGroup = new FormGroup({
                  term: new FormControl(this.student.term_id, [
                    Validators.required,
                  ]),
                  name: new FormControl(this.student.name, [
                    Validators.required,
                  ]),
                  mobile: new FormControl(this.student.mobile, [
                    Validators.required,
                    Validators.pattern("^[0-9]*$"),
                  ]),
                  grade: new FormControl(this.student.grade, [
                    Validators.required,
                  ]),
                  remarks: new FormControl(this.student.remarks),
                  busstop: new FormControl( null , [
                    Validators.required,
                  ]),
                  route: new FormControl(null, []),
                })
                this.apiService.getAvailableTerms().subscribe(terms=>{
                  this.terms = terms;
                  // this.terms = terms.shift();
                  this.student.term = this.terms[0]
                  this.onChangeTerm()
                })
              });
            })
          }else{
            this.studentFormGroup = new FormGroup({
              term: new FormControl(this.student.term_id, [
                Validators.required,
              ]),
              name: new FormControl(this.student.name, [
                Validators.required,
              ]),
              mobile: new FormControl(this.student.mobile, [
                Validators.required,
                Validators.pattern("^[0-9]*$"),
              ]),
              grade: new FormControl(this.student.grade, [
                Validators.required,
              ]),
              remarks: new FormControl(this.student.remarks),
              busstop: new FormControl(this.student.busstop_id, [
                Validators.required,
              ]),
              route: new FormControl(null, []),
            })
            this.student.service = this.services[0].value;
            this.apiService.getAvailableTerms().subscribe(terms=>{
              this.terms = terms;
              // this.terms = terms.shift();
              this.student.term = this.terms[0]
              this.onChangeTerm()
            })
            console.log(this.translate.currentLang)
          }
        })
      })
  }

  checkRouteLimit(): void{
    console.log(this.currentRoute.student_limit + '<=' + this.currentRoute.currentStudentNo);
    if(this.currentRoute.student_limit <= this.currentRoute.currentStudentNo){
      this.studentFormGroup.controls['route'].setErrors({'full': true});
      console.log('full');
    }else{
      this.studentFormGroup.controls['route'].setErrors({'full': null});
      this.studentFormGroup.controls['route'].updateValueAndValidity();
    }
    // if(this.currentRoute.id == 248){ //J12
    //   this.studentFormGroup.controls['route'].setErrors({'J12': true});
    // }else{
    //   this.studentFormGroup.controls['route'].setErrors({'J12': null});
    //   this.studentFormGroup.controls['route'].updateValueAndValidity();
    // }
    console.log(this.studentFormGroup);
  }

  getMode(): string{
    if(window.location.pathname.split('/')[1]=='edit-student')
      return "edit"
    else if(window.location.pathname.split('/')[1]=='continue-student')
      return "continue"
    else 
      return "create"
  }
  
  numberOnly(event:any): boolean {
    console.log(this.studentFormGroup.controls.mobile)
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onChangeSchool(event:any): void {
    if(this.currentSchool.type=='Primary'){
      this.grades = [{
        grade: 1,
        english: "Year 1",
        chinese: "一年級"
      },{
        grade: 2,
        english: "Year 2",
        chinese: "二年級"
      },{
        grade: 3,
        english: "Year 3",
        chinese: "三年級"
      },{
        grade: 4,
        english: "Year 4",
        chinese: "四年級"
      },{
        grade: 5,
        english: "Year 5",
        chinese: "五年級"
      },{
        grade: 6,
        english: "Year 6",
        chinese: "六年級"
      }]
    }else{
      this.grades = [{
        grade: 7,
        english: "Year 7",
        chinese: "七年級"
      },{
        grade: 8,
        english: "Year 8",
        chinese: "八年級"
      },{
        grade: 9,
        english: "Year 9",
        chinese: "九年級"
      },{
        grade: 10,
        english: "Year 10",
        chinese: "十年級"
      },{
        grade: 11,
        english: "Year 11",
        chinese: "十一年級"
      },{
        grade: 12,
        english: "Year 12",
        chinese: "十二年級"
      },{
        grade: 13,
        english: "Year 13",
        chinese: "十三年級"
      }
    ]
    }
  }

  rr():any{
    let routes:any = [];
    this.currentSchool.Routes.forEach( route =>{
      if(this.student.grade >= route.min_grade){
        routes.push(route)
      }
    })
    return routes;
  }

  onChangeTerm(){
    if(this.student.term.status == "Next"){
      this.schools = this.nextSchools
    }else{
      this.schools = this.currentSchools
    }
  }

  letterOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57)||(charCode >= 65 && charCode <= 90)||(charCode >= 97 && charCode <= 122)) {
      return true;
    }
    return false;
  }

  submitForm(): void{
    this.submitting = true
    if(window.location.pathname.split('/')[1]=='continue-student'){
      this.apiService.continueStudent(this.studentFormGroup.controls.name.value, this.studentFormGroup.controls.mobile.value, this.student.grade, this.student.service, this.studentFormGroup.controls.remarks.value, this.student.busstop_id, this.student.term.id, this.student.id).subscribe(res=>{
        if(res.result){
          this.router.navigate(['dashboard'])
        }else{
          this.studentFormGroup.controls['route'].setErrors({'full': true});
          this.submitting = false;
        }
      })
    }else if(window.location.pathname.split('/')[1]=='edit-student'){
      this.apiService.editStudent(this.studentFormGroup.controls.name.value, this.studentFormGroup.controls.mobile.value, this.student.grade, this.studentFormGroup.controls.remarks.value, this.student.id).subscribe(res=>{
        if(res.result){
          this.router.navigate(['dashboard'])
        }else{
          this.studentFormGroup.controls['route'].setErrors({'full': true});
          this.submitting = false;
        }
      })
    }
    else{
      this.apiService.createStudent(this.studentFormGroup.controls.name.value, this.studentFormGroup.controls.mobile.value, this.student.grade, this.student.service, this.studentFormGroup.controls.remarks.value, this.student.busstop_id, this.student.term.id).subscribe(res=>{
        if(res.result){
          this.router.navigate(['dashboard'])
        }else{
          this.studentFormGroup.controls['route'].setErrors({'full': true});
          this.submitting = false;
        }
      })
    }
  }
  return(): void{
    this.router.navigate(['dashboard'])
  }
}
