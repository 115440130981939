<!-- Desktop -->
<div fxShow fxHide.lt-sm fxLayout="column" class="whiteboard-mid">
    <div fxFlex fxLayout="column" >
        <mat-card *ngIf="mode=='default'" class="block" fxLayout="column">
            <div class="block-top" style="margin-top: 10px;">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">account_circle</mat-icon>
                </div>
                <div class="componenttitle" translate>
                    PROFILE.PROFILE
                </div>
            </div>
            <span class="content_title greentextcontent" translate>PROFILE.LANGUAGE_PREFERENCE</span>
            <mat-form-field appearance="fill" style="width:100%; margin-top: 10px;">
                <mat-label translate>PROFILE.SELECT_LANGUAGE</mat-label>
                <mat-select [(ngModel)]="language" (ngModelChange)="onChangeLanguage($event)" [ngModelOptions]="{standalone: true}">
                  <mat-option [value]="'English'">
                    English
                  </mat-option>
                  <mat-option [value]="'Chinese'">
                    繁體中文
                  </mat-option>
                </mat-select>
              </mat-form-field>
            <span class="content_title greentextcontent" translate>PROFILE.BASIC_INFORMATION</span>
            <form [formGroup]="userInfoFormGroup" fxFlex fxLayout="column">
                <mat-form-field appearance="fill">
                    <mat-label translate>PROFILE.NAME</mat-label>
                    <input matInput placeholder="{{'PROFILE.NAME'|translate}}" formControlName="name" [attr.disabled]=true>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label translate>PROFILE.EMAIL</mat-label>
                    <input matInput placeholder="'PROFILE.EMAIL'|translate" formControlName="email" required>
                    <mat-error *ngIf="userInfoFormGroup.controls.email.errors" translate>PROFILE.INVALID_EMAIL_FORMAT</mat-error>
                </mat-form-field>
                <div>
                    <button style="float:left;" (click)="mode='changePassword'" mat-stroked-button color="accent">{{'PROFILE.CHANGE_PASSWORD'|translate}}</button>
                    <button style="float:right" (click)="submitForm()" mat-raised-button color="accent"><span *ngIf="userInfoFormGroup.disabled" translate>PROFILE.EDIT</span></button>
                    <button style="float:right" [disabled]='userInfoFormGroup.pristine' (click)="submitForm()" mat-raised-button color="accent"><span *ngIf="!userInfoFormGroup.disabled" translate>PROFILE.UPDATE</span></button>
                </div>
            </form>
            <span class="content_title greentextcontent" style="margin-top: 30px;" translate>PROFILE.REGISTERED_PHONE_NUMBER</span>
            <form [formGroup]="phoneFormGroup" fxFlex fxLayout="column">
                <mat-form-field appearance="fill">
                    <mat-label translate>PROFILE.PHONE_NUMBER</mat-label>
                    <input (keypress)="numberOnly($event)" matInput placeholder="{{'PROFILE.PHONE_NUMBER'|translate}}" formControlName="phone" required>
                </mat-form-field>
                <span class="poppinsR" translate>PROFILE.PHONENUMBER_DESCRIPTION</span>
                <button (click)="submitPhoneForm()" style="float:left; margin-top: 10px;" mat-stroked-button color="accent">{{'PROFILE.CHANGE_PHONE_NUMBER'|translate}}</button>
            </form>
        </mat-card>
        <mat-card *ngIf="mode=='changePassword'" class="block" fxLayout="column">
            <div class="block-top">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">info_outline</mat-icon>
                </div>
                <div class="componenttitle" translate>
                    PROFILE.CHANGE_PASSWORD
                </div>
            </div>
            <form [formGroup]="passwordFormGroup" fxFlex fxLayout="column">
                <mat-form-field appearance="fill">
                    <mat-label translate>PROFILE.OLD_PASSWORD</mat-label>
                    <input type="password" (keypress)="letterOnly($event)" matInput placeholder="{{'PROFILE.OLD_PASSWORD'|translate}}" formControlName="oldpassword" required>
                    <mat-error *ngIf="passwordFormGroup.controls.oldpassword.invalid" translate>PROFILE.MINIMUM_PASSWORD_LENGTH_ERROR</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label translate>PROFILE.NEW_PASSWORD</mat-label>
                    <input type="password" (keypress)="letterOnly($event)" matInput placeholder="{{'PROFILE.NEW_PASSWORD'|translate}}" formControlName="newpassword1" required>
                    <mat-error *ngIf="passwordFormGroup.controls.newpassword1.invalid" translate>PROFILE.MINIMUM_PASSWORD_LENGTH_ERROR</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label translate>PROFILE.CONFIRM_NEW_PASSWORD</mat-label>
                    <input type="password" (keypress)="letterOnly($event)" matInput placeholder="{{'PROFILE.CONFIRM_NEW_PASSWORD'|translate}}" formControlName="newpassword2" required>
                    <mat-error *ngIf="passwordFormGroup.controls.newpassword2.invalid" translate>PROFILE.MINIMUM_PASSWORD_LENGTH_ERROR</mat-error>
                </mat-form-field>
                <span *ngIf="passwordFormError" style="color:red">{{passwordFormError}}</span>
                <button [disabled]="passwordFormGroup.status !== 'VALID'" (click)="submitPasswordForm()" style="float:left;" mat-raised-button color="accent">{{'PROFILE.CHANGE_PASSWORD'|translate}}</button>
            </form>
        </mat-card>
    </div>
</div>
<!-- Mobile -->
<div fxHide fxShow.lt-sm fxLayout="column">
    <div fxFlex fxLayout="column">
        <mat-card *ngIf="mode=='default'" class="block" fxLayout="column">
            <div class="block-top">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">info_outline</mat-icon>
                </div>
                <div class="componenttitle" translate>
                    PROFILE.PROFILE
                </div>
            </div>
            <span class="content_title greentextcontent" translate>PROFILE.LANGUAGE_PREFERENCE</span>
            <mat-form-field appearance="fill" style="width:100%">
                <mat-label translate>PROFILE.SELECT_LANGUAGE</mat-label>
                <mat-select [(ngModel)]="language" (ngModelChange)="onChangeLanguage($event)" [ngModelOptions]="{standalone: true}">
                  <mat-option [value]="'English'">
                    English
                  </mat-option>
                  <mat-option [value]="'Chinese'">
                    繁體中文
                  </mat-option>
                </mat-select>
              </mat-form-field>
            <span class="content_title greentextcontent" translate>PROFILE.BASIC_INFORMATION</span>
            <form [formGroup]="userInfoFormGroup" fxFlex fxLayout="column">
                <mat-form-field appearance="fill">
                    <mat-label translate>PROFILE.NAME</mat-label>
                    <input matInput placeholder="{{'PROFILE.NAME'|translate}}" formControlName="name" [attr.disabled]=true>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label translate>PROFILE.EMAIL</mat-label>
                    <input matInput placeholder="{{'PROFILE.EMAIL'|translate}}" formControlName="email" required>
                    <mat-error *ngIf="userInfoFormGroup.controls.email.errors" translate>PROFILE.INVALID_EMAIL_FORMAT</mat-error>
                </mat-form-field>
                <div>
                    <button style="float:left;" (click)="mode='changePassword'" mat-stroked-button color="accent">{{'PROFILE.CHANGE_PASSWORD'|translate}}</button>
                    <button style="float:right" (click)="submitForm()" mat-raised-button color="accent"><span *ngIf="userInfoFormGroup.disabled" translate>PROFILE.EDIT</span></button>
                    <button style="float:right" [disabled]='userInfoFormGroup.pristine' (click)="submitForm()" mat-raised-button color="accent"><span *ngIf="!userInfoFormGroup.disabled" translate>PROFILE.UPDATE</span></button>
                </div>
            </form>
            <span class="content_title greentextcontent" style="margin-top: 15px;" translate>PROFILE.REGISTERED_PHONE_NUMBER</span>
            <form [formGroup]="phoneFormGroup" fxFlex fxLayout="column">
                <mat-form-field appearance="fill">
                    <mat-label translate>PROFILE.PHONE_NUMBER</mat-label>
                    <input (keypress)="numberOnly($event)" matInput placeholder="Phone Number" formControlName="phone" required>
                </mat-form-field>
                <span class="poppinsR" translate>PROFILE.PHONENUMBER_DESCRIPTION</span>
                <button (click)="submitPhoneForm()" style="float:left; margin-top: 10px;" mat-stroked-button color="accent">{{'PROFILE.CHANGE_PHONE_NUMBER'|translate}}</button>
            </form>
        </mat-card>
        <mat-card *ngIf="mode=='changePassword'" class="block" fxLayout="column">
            <div class="block-top">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">info_outline</mat-icon>
                </div>
                <div class="title" style="margin-left:96px;" translate>
                    PROFILE.CHANGE_PASSWORD
                </div>
            </div>
            <form [formGroup]="passwordFormGroup" fxFlex fxLayout="column">
                <mat-form-field class="Steppercontent-margintop" appearance="fill">
                    <mat-label translate>PROFILE.OLD_PASSWORD</mat-label>
                    <input type="password" (keypress)="letterOnly($event)" matInput placeholder="{{'PROFILE.OLD_PASSWORD'|translate}}" formControlName="oldpassword" required>
                    <mat-error class="formerror" *ngIf="passwordFormGroup.controls.oldpassword.invalid" translate>PROFILE.MINIMUM_PASSWORD_LENGTH_ERROR</mat-error>
                </mat-form-field>
                <mat-form-field  class="Steppercontent-margintop" appearance="fill">
                    <mat-label translate>PROFILE.NEW_PASSWORD</mat-label>
                    <input type="password" (keypress)="letterOnly($event)" matInput placeholder="{{'PROFILE.NEW_PASSWORD'|translate}}" formControlName="newpassword1" required>
                    <mat-error class="formerror" *ngIf="passwordFormGroup.controls.newpassword1.invalid" translate>PROFILE.MINIMUM_PASSWORD_LENGTH_ERROR</mat-error>
                </mat-form-field>
                <mat-form-field  class="Steppercontent-margintop" appearance="fill">
                    <mat-label translate>PROFILE.CONFIRM_NEW_PASSWORD</mat-label>
                    <input type="password" (keypress)="letterOnly($event)" matInput placeholder="{{'PROFILE.CONFIRM_NEW_PASSWORD'|translate}}" formControlName="newpassword2" required>
                    <mat-error class="formerror" *ngIf="passwordFormGroup.controls.newpassword2.invalid" translate>PROFILE.MINIMUM_PASSWORD_LENGTH_ERROR</mat-error>
                </mat-form-field>
                <span *ngIf="passwordFormError" style="color:red">{{passwordFormError}}</span>
                <button class="Steppercontent-margintop" (click)="submitPasswordForm()" style="float:left;" mat-stroked-button color="accent">{{'PROFILE.CHANGE_PASSWORD'|translate}}</button>
            </form>
        </mat-card>
    </div>
</div>