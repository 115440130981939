import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service'

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.css']
})
export class ForgotPasswordPageComponent implements OnInit {
  recaptcha:string= '';
  mobile: string= '';
  thankyou: boolean= false;
  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  completeRecaptcha(event:string){
    this.recaptcha = event
  }

  resetPassword(){
    this.apiService.resetPassword(this.mobile, this.recaptcha).subscribe(result=>{
      if(result.result){
        this.thankyou=true;
        setTimeout(()=>{
          this.router.navigate(['/log-in']);
        }, 5000);
      }
    })
  }

}
