<div class="container" [ngClass.lt-md]="{'mobile': true}" [ngClass.md]="{'pad': true}">
<div class="menu">
  <span class="logo" (click)="dashboard()" style="cursor: pointer;">Kong Shing Travel LTD</span>
  <div class="button-container" fxShow fxHide.lt-md>
    <div *ngIf="loginService.isLoggedIn()" class="button" (click)="dashboard()">
      <mat-icon class="icon">home</mat-icon><span class="label" translate>MENU.DASHBOARD</span>
    </div>
    <div class="button" (click)="routeList()">
      <mat-icon class="icon">directions_bus_filled</mat-icon><span class="label" translate>MENU.ROUTE_LIST</span>
    </div>
    <div *ngIf="loginService.isLoggedIn() && notification_no" class="button" (click)="inbox()">
      <mat-icon class="icon">mail_outline</mat-icon><span class="label" translate [matBadge]="notification_no">MENU.INBOX</span>
    </div>
    <div *ngIf="loginService.isLoggedIn() && !notification_no" class="button" (click)="inbox()">
      <mat-icon class="icon">mail_outline</mat-icon><span class="label" translate>MENU.INBOX</span>
    </div>
    <div *ngIf="loginService.isLoggedIn()" class="button" (click)="profile()">
      <mat-icon class="icon">account_circle</mat-icon><span class="label" translate>MENU.PROFILE</span>
    </div>
    <div class="button" (click)="changeLanguage()">
      <mat-icon class="icon">language</mat-icon><span class="label MHeiHK" translate>MENU.CHANGE_LANGUAGE</span>
    </div>
    <div *ngIf="loginService.isLoggedIn()" class="button" (click)="logout()">
      <mat-icon class="icon">logout</mat-icon><span class="label" translate>MENU.LOGOUT</span>
    </div>
  </div>
  <button class="button-container"  mat-icon-button [matMenuTriggerFor]="menu" fxHide fxShow.lt-md>
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu style="float:right" #menu="matMenu" fxHide fxShow.lt-md>
    <button *ngIf="loginService.isLoggedIn()" mat-menu-item (click)="dashboard()">
      <mat-icon>home</mat-icon>
      <span translate>MENU.DASHBOARD</span>
    </button>
    <button *ngIf="loginService.isLoggedIn() && notification_no" (click)="inbox()" mat-menu-item>
        <mat-icon>mail_outline</mat-icon>
        <span translate [matBadge]="notification_no">MENU.INBOX</span>
    </button>
    <button *ngIf="loginService.isLoggedIn() && !notification_no" (click)="inbox()" mat-menu-item>
      <mat-icon>mail_outline</mat-icon>
      <span translate>MENU.INBOX</span>
    </button>
    <button (click)="routeList()" mat-menu-item>
        <mat-icon>directions_bus_filled</mat-icon>
        <span translate>MENU.ROUTE_LIST</span>
    </button>
    <button *ngIf="loginService.isLoggedIn()" (click)="profile()" mat-menu-item>
      <mat-icon>account_circle</mat-icon>
      <span translate>MENU.PROFILE</span>
    </button>
    <button (click)="changeLanguage()" mat-menu-item>
      <mat-icon>language</mat-icon>
      <span translate>MENU.CHANGE_LANGUAGE</span>
    </button>
    <button *ngIf="loginService.isLoggedIn()" (click)="logout()" mat-menu-item>
      <mat-icon>logout</mat-icon>
      <span translate>MENU.LOGOUT</span>
    </button>
  </mat-menu>
</div>
<!-- <nav>
  <ul>
    <li><a routerLink="/log-in" routerLinkActive="active">Log In</a></li>
  </ul>
</nav> -->
<div class="whiteboard">
<router-outlet></router-outlet>
</div>
</div>