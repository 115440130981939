<!-- Desktop -->
<!-- <mat-card fxShow fxHide.lt-sm> -->
<mat-card fxShow class="whiteboard-mid">
    <div *ngIf="!thankyou" class="block">
        <div class="block-top" style="margin-top: 10px;">
            <div class="block-label">
                <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">lock</mat-icon>
            </div>
            <div class="componenttitle" translate>
            FORGOT_PASSWORD.FORGOT_PASSWORD
            </div>
        </div>
        <div translate style="margin-top: 20px;">
            FORGOT_PASSWORD.WARNING
        </div>
        <div style="margin-top: 10px;">
            <mat-form-field>
                <mat-label translate>FORGOT_PASSWORD.PHONE_NUMBER</mat-label>
                <input [(ngModel)]="mobile" (keypress)="numberOnly($event)" matInput #message placeholder="{{ 'FORGOT_PASSWORD.PHONE_NUMBER' | translate }}">
            </mat-form-field>
            <re-captcha
                (resolved)="completeRecaptcha($event)"
                siteKey="6LcIsVgaAAAAAPTm2FzktFm5D_fM_XjZq1yc4Kn-"
            ></re-captcha>
            <div style="margin-top: 10px;">
                <button mat-raised-button color="accent" (click)="resetPassword()" [disabled]="(mobile==='') || (mobile.length <8) || recaptcha==''">{{ 'FORGOT_PASSWORD.RESET_PASSWORD' | translate }}</button>
            </div>
        </div>
    </div>
    <div *ngIf="thankyou" class="block">
        <div class="block-top" style="margin-top: 10px;">
            <div class="block-label">
                <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">lock</mat-icon>
            </div>
            <div class="componenttitle" translate>
                FORGOT_PASSWORD.RESET_PASSWORD
            </div>
        </div>
        <div [innerHTML]="'FORGOT_PASSWORD.THANK_YOU' | translate">
        </div>
    </div>
</mat-card>