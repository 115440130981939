import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators, AbstractControl, ValidatorFn} from '@angular/forms';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userInfoFormGroup: FormGroup = <FormGroup>{}
  phoneFormGroup: FormGroup = <FormGroup>{}
  passwordFormGroup: FormGroup = <FormGroup>{}
  passwordFormError:string = "";
  userInfoFormDisabled:boolean = false;
  phoneFormDisabled:boolean = false;
  mode: string = "default"
  language: string = 'English'
  constructor(private apiService:ApiService, private router:Router, public translateService:TranslateService) {
  }

  reload():void{
    this.mode = "default";
    this.apiService.getMemberProfile().subscribe(res=>{
      this.language = res.language_preference;
      this.userInfoFormGroup = new FormGroup({
        name: new FormControl(res.name, [
          Validators.required,
        ]),
        email: new FormControl(res.email, [
          Validators.required,
          Validators.email,
        ]),
      })
      this.phoneFormGroup = new FormGroup({
        phone: new FormControl(res.phone, [
          Validators.required,
        ]),
      })
      this.passwordFormGroup = new FormGroup({
        oldpassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
        newpassword1: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
        newpassword2: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
      })
      this.userInfoFormGroup.disable()
      this.phoneFormGroup.disable()
    })
  }
  
  ngOnInit(): void {
    this.reload();
    this.userInfoFormGroup = new FormGroup({
      name: new FormControl('', [
        Validators.required,
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
    })
    this.phoneFormGroup = new FormGroup({
      phone: new FormControl('', [
        Validators.required,
      ]),
    })
    this.userInfoFormGroup.disable()
    this.phoneFormGroup.disable()
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  letterOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57)||(charCode >= 65 && charCode <= 90)||(charCode >= 97 && charCode <= 122)) {
      return true;
    }
    return false;
  }

  submitForm(){
    console.log(this.userInfoFormGroup)
    if(this.userInfoFormGroup.disabled){
      this.userInfoFormGroup.enable()
    }else{
      this.apiService.updateMemberProfile(this.userInfoFormGroup.controls.name.value, this.userInfoFormGroup.controls.email.value)
      .subscribe(res=>{
        this.reload()
      })
    }
  }

  submitPasswordForm(){
    if(this.passwordFormGroup.controls.newpassword1.value!==this.passwordFormGroup.controls.newpassword2.value){
      if(this.translateService.currentLang == 'en'){
        this.passwordFormError += "Passwords are not same<br>"
      }else{
        this.passwordFormError += "密碼不一致<br>"
      }
    }else{
      this.apiService.updateMemberPassword(this.passwordFormGroup.controls.oldpassword.value,this.passwordFormGroup.controls.newpassword1.value).subscribe(res=>{
        if(!res.result){
          switch(res.error){
            case "PASSWORD":
              if(this.translateService.currentLang == 'en'){
                this.passwordFormError = "Incorrect old password!"
              }else{
                this.passwordFormError = "不正確的原密碼"
              }
              break;
            default:
              if(this.translateService.currentLang == 'en'){
                this.passwordFormError = "Unknown Error!"
              }else{
                this.passwordFormError = "伺服器錯誤！"
              }
          }
        }else{
          this.reload();
        }
      })
    }
  }

  onChangeLanguage(lang:string){
    console.log(lang)
    this.apiService.updateMemberLanguage(lang)
      .subscribe(res=>{
        this.reload()
      })
  }

  submitPhoneForm(){
    if(this.phoneFormGroup.disabled){
      this.phoneFormGroup.enable()
    }else{
      this.apiService.updateMemberPhone(this.phoneFormGroup.controls.phone.value)
      .subscribe(res=>{
        this.reload()
      })
    }
  }
}
