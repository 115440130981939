import { Component, OnInit } from '@angular/core';
import { ApiService }  from '../api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-route-list',
  templateUrl: './route-list.component.html',
  styleUrls: ['./route-list.component.css']
})
export class RouteListComponent implements OnInit {
  public currentTerm:any
  public terms:any
  public currentSchool:any
  constructor(private apiService: ApiService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.apiService.getRouteTerms().subscribe(terms=>{
      this.terms = terms
      this.currentTerm = terms[0]
      this.currentTerm.PDF.Schools = JSON.parse(this.currentTerm.PDF.data)
      this.currentSchool = this.currentTerm.PDF.Schools[0]
    })
  }

  onChangeTerm(e:any){
    this.currentTerm = e
    this.currentTerm.PDF.Schools = JSON.parse(this.currentTerm.PDF.data)
    this.currentSchool = this.currentTerm.PDF.Schools[0]
  }

}
