import { Component, OnInit, ViewChild } from '@angular/core';

import { ApiService }  from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Student } from '../types';
import { JwtImagePipe } from '../jwt-image.pipe';
import * as _ from "lodash";
import {MatAccordion} from '@angular/material/expansion';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  providers:[ JwtImagePipe ]
})

export class PaymentComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion = <MatAccordion>{};
  public students:Student[]=[];
  expandStudentId: number[] = [];
  selectedStudents:Student[] = [];
  public discount = 0;
  files:any = []
  public paymentRecord: any;
  public payment_method: string = "Bank Transfer"
  public _ = _;
  public submitting = false

  step = 1;
  image:string = "";
  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute, public translate: TranslateService, public jwtImagePipe: JwtImagePipe) { 
  }

  ngOnInit(): void {    
    this.apiService.getLatestPaymentRecord().subscribe(res=>{
      if(res.paymentRecord && (res.paymentRecord.status=='Waiting'||res.paymentRecord.status=='Waiting(Late Payment)'||res.paymentRecord.status=='Waiting(Late Registration)'||res.paymentRecord.status=='Waiting(Transfer)')){
        this.paymentRecord = res.paymentRecord
        this.files = this.paymentRecord.PaymentSlips
        this.payment_method = this.paymentRecord.payment_method
      }
      this.apiService.getStudents().subscribe(res=>{
        this.students = _.filter(res, {status: "Pending"});
        res.forEach((student:any)=>{
          if(student.status == 'Active' && student.Term.status == 'Next'){
            this.discount =100;
          }
        })
        if(this.paymentRecord){
          this.students.forEach((student)=>{
            if(_.find(this.paymentRecord.Students, {id: student.id}))
              this.selectedStudents.push(student)
          })
        }else{
          this.students.forEach((student)=>{
            this.selectedStudents.push(student)
          })
        }
      })
    })
  }

  selectStudent(student:Student){
    if(_.find(this.selectedStudents, student)){
      _.remove(this.selectedStudents, student)
    }else{
      this.selectedStudents.push(student)
    }
  }

  getDiscount():number{
    let discountPrice = -100;
    for(let a=0;a<this.selectedStudents.length;a++){
      if(this.selectedStudents[a].Term.status == 'Next')
      discountPrice = discountPrice+100;
    }
    if(discountPrice<0){
      discountPrice = 0;
    }
    return discountPrice+this.discount;
  }

  getTotal():number{
    let total = 0;
    for(let a=0;a<this.selectedStudents.length;a++){
      total += this.selectedStudents[a].price;
    }
    total = total - this.getDiscount()
    return total;
  }

  onFileSelect(event:any) {
    if (event.target.files.length > 0) {
      this.submit(event.target.files[0])
    }
    // else{
    //   console.log("event",event)
    //   this.submit()
    // }
  }

  removeSlip(imageId: number){
    let newFiles:any = []
    this.files.forEach((file:any)=>{
      if(file.id !== imageId){
        newFiles.push(file)
      }
    })
    this.files = newFiles;
  }

  submit(file:any=null) {
    if(this.submitting == false){
      if(file){
        const formData = new FormData();
        formData.append('slip', file);
        this.apiService.uploadPaymentSlip(formData).subscribe(res=>{
          if(res.result){
            if(this.files.length){
              this.files.push(res.file)
            }else{
              this.files = [res.file]
            }
            if(this.step == 1){
              this.step = 2;
            }
          }
        })
      }else{
        let studentIds:any = []
        let fileIds:any = []
        this.selectedStudents.forEach(student=>{
          studentIds.push(student.id)
        })
        this.files.forEach( (file:any) =>{
          fileIds.push(file.id)
        })
        this.submitting = true;
        if(this.paymentRecord){
          this.apiService.updatePaymentRecord(this.paymentRecord.id, studentIds, fileIds, this.getTotal(), this.payment_method).subscribe(res=>{
            this.router.navigate(['dashboard'])
          })
        }else{
          this.apiService.createPaymentRecord(studentIds, fileIds, this.getTotal(), this.payment_method).subscribe(res=>{
            this.router.navigate(['dashboard'])
          })
        }
      }
    }
  }

  goBack() {
    this.router.navigate(['dashboard'])
  }
}
