<!-- Desktop -->
<mat-card fxShow fxHide.lt-md>
    <div class="block" fxFlex="60%" style="border-right: 1px solid gray;">
        <div class="block-top">
            <div class="block-label">
                <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">info_outline</mat-icon>
            </div>
            <div class="componenttitle" translate>
            LOGIN.NEWS
            </div>
        </div>
        <div class="content">
            <div class="newstitle">
                <span fxFlex="30%" class="news_subtitle poppinsR" translate>LOGIN.DATE</span>
                <span fxFlex="70%" class="news_subtitle poppinsR" translate>LOGIN.MESSAGE</span>
            </div>
            <div *ngFor="let n of news" class="row newsmessage">
                <span fxFlex="30%" class="poppinsR">{{moment(n.date).format('DD/MM/YYYY')}}</span>
                <!-- <span *ngIf="n.read_more" fxFlex="70%" class="poppinsR">{{n.content}}</span>
                <span *ngIf="!n.read_more" fxFlex="70%" class="poppinsR"></span> -->
                <div fxFlex="70%" class="poppinsR">
                    <div *ngIf="!n.showMore" class="content-box" [innerHTML]="translate.currentLang=='en'?n.content:n.content_chinese">
                    </div>
                    <div *ngIf="n.showMore" class="content-box-show-more" [innerHTML]="translate.currentLang=='en'?n.content:n.content_chinese">
                    </div>
                    <div (click)="n.showMore=!n.showMore" *ngIf="!n.showMore" class="show-button">{{'INBOX_POP.SHOW_MORE'|translate}}</div>
                    <div (click)="n.showMore=!n.showMore" *ngIf="n.showMore" class="show-button">{{'INBOX_POP.SHOW_LESS'|translate}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="block" fxFlex="40%">
        <div class="block-top">
            <div class="block-label">
                <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">login_outline</mat-icon>
            </div>
            <div class="componenttitle" translate>
            LOGIN.LOGIN
            </div>
        </div>
        <div class="content">
            <mat-form-field>
                <mat-label><span translate>LOGIN.USERNAME</span></mat-label>
                <input [(ngModel)]="username" matInput #message placeholder="{{ 'LOGIN.USERNAME' | translate }}">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label><span translate>LOGIN.PASSWORD</span></mat-label>
                <input [(ngModel)]="password" type="password" matInput #message placeholder="{{ 'LOGIN.PASSWORD' | translate }}">
            </mat-form-field>
            <span *ngIf="loginError" style="color:red;" translate>LOGIN.INCORRECT_AUTH</span><br>
            <span *ngIf="maintenance.online=='false' && translate.currentLang!='en'" style="color:red;">{{maintenance.maintenanceMessageChinese}}<br></span>
            <span *ngIf="maintenance.online=='false' && translate.currentLang=='en'" style="color:red;">{{maintenance.maintenanceMessageEnglish}}<br></span>
            <span routerLink="/forgot-password" style="color:#1492E6; cursor:pointer;" class="poppinsR" translate>LOGIN.FORGOT_PASSWORD</span>
            <br>
            <button (click)="login()" mat-raised-button  color="accent" class="desktopbuttonstyle"><span translate>LOGIN.LOGIN</span></button>
            <br>
            <span style="color:#7E7E7E" class="poppinsR" translate>LOGIN.BOOKING_SYSTEM_WARNING</span>
            <br>
            <button routerLink="/registration" mat-stroked-button  color="accent" class="desktopbuttonstyle">{{ 'LOGIN.REGISTER_NOW' | translate }}</button>
        </div>
    </div>
</mat-card>
<!-- Mobile -->
<mat-card fxHide fxShow.lt-md >
    <div class="block" fxFlex fxLayout="column">
        <div class="block-top">
            <div class="block-label">
                <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">info_outline</mat-icon>
            </div>
            <div class="title" translate>
                LOGIN.NEWS
            </div>
        </div>
        <div class="content">
            <div *ngFor="let n of news" style="margin-bottom: 20px; border-bottom: 1.2px solid rgba(0,0,0,.20);">
                <div class="news_contentpaddingbottom">
                    <span class="poppinsR" style="color:gray;">{{moment(n.date).format('DD/MM/YYYY')}}</span>
                </div>
                <div class="news_contentpaddingbottom2">
                    <div *ngIf="!n.showMore" class="content-box" [innerHTML]="translate.currentLang=='en'?n.content:n.content_chinese">
                    </div>
                    <div *ngIf="n.showMore" class="content-box-show-more" [innerHTML]="translate.currentLang=='en'?n.content:n.content_chinese">
                    </div>
                </div>
                <div (click)="n.showMore=!n.showMore" *ngIf="!n.showMore" class="show-button">{{'INBOX_POP.SHOW_MORE'|translate}}</div>
                <div (click)="n.showMore=!n.showMore" *ngIf="n.showMore" class="show-button">{{'INBOX_POP.SHOW_LESS'|translate}}</div>
            </div>
        </div>
    </div>
    <div fxFlex fxLayoutAlign="center" style="position: fixed;bottom:20px;left:0;right:0;">
        <button mat-raised-button color="accent" style="height:50px;width:300px;max-width:90%" routerLink="/log-in" [fragment]="'login'">{{ 'LOGIN.LOGIN' | translate }}</button>
    </div>
</mat-card>
<mat-card fxHide fxShow.lt-md style="margin-top: 40px;" id="login">
    <div class="block" fxFlex fxLayout="column">
        <div class="block-top">
            <div class="block-label">
            <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">login</mat-icon>
            </div>
            <div class="title" translate>
            LOGIN.LOGIN
            </div>
        </div>
        <div class="content" fxFlex fxLayout="column">
            <mat-form-field>
                    <mat-label translate>LOGIN.USERNAME</mat-label>
                    <input [(ngModel)]="username" matInput #message placeholder="{{ 'LOGIN.USERNAME' | translate }}">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label translate>LOGIN.PASSWORD</mat-label>
                <input [(ngModel)]="password" type="password" matInput #message placeholder="{{ 'LOGIN.PASSWORD' | translate }}">
            </mat-form-field>
            <span *ngIf="loginError" style="color:red; font-family: poppinsR;" translate>LOGIN.INCORRECT_AUTH</span><br>
            <span *ngIf="maintenance.online=='false' && translate.currentLang!='en'" style="color:red; font-family: poppinsR;">{{maintenance.maintenanceMessageChinese}}<br></span>
            <span *ngIf="maintenance.online=='false' && translate.currentLang=='en'" style="color:red; font-family: poppinsR;">{{maintenance.maintenanceMessageEnglish}}<br></span>
            <div class="div_paddingbottom">
                <span routerLink="/forgot-password" style="color:#1492E6; font-family: poppinsR;" translate>LOGIN.FORGOT_PASSWORD</span>
                <button (click)="login()" mat-raised-button color="accent" style="float: right;">{{ 'LOGIN.LOGIN' | translate }}</button>
            </div>
                <span class="eng_content1 div_paddingbottom" translate>LOGIN.BOOKING_SYSTEM_WARNING</span>
                <button style="margin-bottom:15px" routerLink="/registration" mat-stroked-button color="accent">{{ 'LOGIN.REGISTER_NOW' | translate }}</button>
        </div>
    </div>
</mat-card>

