
<!-- Desktop -->
<div fxShow fxHide.lt-sm fxLayout="column">
    <div fxFlex fxLayout="column" >
        <mat-card class="block" fxLayout="column">
            <div class="block-top" style="margin-top:10px;">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">space_dashboard</mat-icon>
                </div>
                <div class="componenttitle" translate>
                    DASHBOARD.DASHBOARD
                </div>
            </div>
            <div fxLayout="row">
                <div style="min-width: 300px;border-right: solid gray 1px;padding: 20px;" fxLayout="column">
                    <span class="greentextcontent" translate>DASHBOARD.REGISTERED_STUDENTS</span>
                    <div *ngFor="let student of students" class="student-button" [ngClass]="{'selected': student==currentStudent}" (click)="currentStudent = student">
                        {{student.current?student.current.name:student.next.name}}
                    </div>
                    <div style="margin:20px 0; border-top: 1px solid black;"></div>
                    <button mat-raised-button color="accent" (click)="createStudent()" >{{ 'DASHBOARD.CREATE_STUDENT' | translate }}</button>
                    <button mat-raised-button color="accent" *ngIf="(this.currentStudent && this.currentStudent.current) || (this.currentStudent && this.currentStudent.next)" (click)="editStudent()" style="margin-top: 15px;">{{ 'DASHBOARD.EDIT_STUDENT' | translate }}</button>
                    <button mat-raised-button color="accent" (click)="paymentHistory()"  style="margin-top: 15px;">{{ 'DASHBOARD.PAYMENT_HISTORY' | translate }}</button>
                </div>
                <div style="width: 100%;padding: 20px;" fxLayout="column" *ngIf="currentStudent">
                    <button style="min-height:108px;" *ngIf="currentStudent.current && currentStudent.current.status=='Active' && terms && terms[0].status=='Next' && terms[0].open &&!currentStudent.next" mat-raised-button color="accent" (click)="continueStudent(currentStudent.current.id)">{{ 'DASHBOARD.REGISTER_NEXT_TERM' | translate }}</button>
                    <span *ngIf="currentStudent.current && (currentStudent.current.status=='Pending'||currentStudent.current.status=='Quote')" class="greentextcontent" translate>DASHBOARD.PENDING_PAYMENT</span>
                    <div class="information-block" fxFlex fxLayout="column" *ngIf="currentStudent.current && (currentStudent.current.status=='Pending'||currentStudent.current.status=='Quote')">
                        <div>
                            <span class="eng_content1" style="color: #1b9d77;">
                                {{translate.currentLang=='en'?currentStudent.current.Term.name:currentStudent.current.Term.name_chinese}}
                            </span>
                        </div>
                        <div>
                            <span class="eng_content2">
                                {{currentStudent.current.name}}
                            </span>
                        </div>
                        <div>
                            <span class="eng_content1">
                                {{ 'DASHBOARD.ROUTE' | translate }} {{currentStudent.current.BusStop.Route.name}}
                            </span>
                        </div>
                        <div>
                            <span class="eng_content1">
                                {{translate.currentLang=='en'?currentStudent.current.BusStop.name:currentStudent.current.BusStop.name_chinese}}
                            </span>
                        </div>

                        <div *ngIf="currentStudent.current.price">
                            <span style="float:right;font-size: 30px;">
                                {{currentStudent.current.price | currency:'HKD'}}
                            </span>
                        </div>
                        <div fxFlex fxLayout="column" style="margin-top: 15px;">
                            <div fxFlex fxLayout="row" *ngIf="!currentStudent.current.price">
                                <div class="step-fab" [ngClass]="{'inactive-background': !(getPaymentStepper(currentStudent.current.PaymentRecords)==1)}">
                                    <div class="step-fabno">
                                        <span style="margin:auto;">!</span>
                                    </div>
                                </div>
                                <div class="step-info" [ngClass]="{'inactive-font': !(getPaymentStepper(currentStudent.current.PaymentRecords)==1)}" translate>DASHBOARD.WAITING_FOR_QUOTE</div>
                            </div>
                            <div fxFlex fxLayout="row" *ngIf="currentStudent.current.price" style="margin-top: 15px;">
                                <div class="step-fab" [ngClass]="{'inactive-background': !(getPaymentStepper(currentStudent.current.PaymentRecords)==1)}">
                                    <div class="step-fabno">
                                        <span style="margin:auto;">1</span>
                                    </div>
                                </div>
                                <div class="step-info" [ngClass]="{'inactive-font': !(getPaymentStepper(currentStudent.current.PaymentRecords)==1)}" translate>DASHBOARD.PLEASE_UPLOAD</div>
                            </div>
                            <div fxFlex fxLayout="row" *ngIf="currentStudent.current.price" style="margin-top: 15px;">
                                <div class="step-fab" [ngClass]="{'inactive-background':  !(getPaymentStepper(currentStudent.current.PaymentRecords)==2)}">
                                    <div class="step-fabno">
                                        <span style="margin:auto;">2</span>
                                    </div>
                                </div>
                                <div style="margin-top: 5px;margin-bottom: 5px;" class="step-info" [ngClass]="{'inactive-font':  !(getPaymentStepper(currentStudent.current.PaymentRecords)==2)}" translate>DASHBOARD.WAITING_CONFIRMATION</div>
                            </div>
                            <div class="button_margintop">
                                <button *ngIf="!currentPaymentRecord && currentStudent.current.price" (click)="gotoPayment()" fxFlex mat-raised-button color="accent">{{ 'DASHBOARD.UPLOAD_PAYMENT_SLIP' | translate }}</button>
                                <button *ngIf="currentPaymentRecord && currentStudent.current.price" (click)="gotoPayment()" fxFlex mat-raised-button color="accent">{{ 'DASHBOARD.EDIT_PAYMENT_SLIP' | translate }}</button>
                            </div>    
                        </div>
                    </div>
                    <span *ngIf="currentStudent.next && (currentStudent.next.status=='Pending'||currentStudent.next.status=='Quote')" class="greentextcontent" translate>DASHBOARD.PENDING_PAYMENT</span>
                    <div class="information-block" fxFlex fxLayout="column" *ngIf="currentStudent.next && (currentStudent.next.status=='Pending'||currentStudent.next.status=='Quote')">
                        <div>
                            <span class="eng_content1" style="color: #1b9d77;">
                                {{translate.currentLang=='en'?currentStudent.next.Term.name:currentStudent.next.Term.name_chinese}}
                            </span>
                        </div>
                        <div>
                            <span class="eng_content2">
                                {{currentStudent.next.name}}
                            </span>
                        </div>
                        <div>
                            <span class="eng_content1">
                                {{ 'DASHBOARD.ROUTE' | translate }} {{currentStudent.next.BusStop.Route.name}}
                            </span>
                        </div>
                        <div>
                            <span class="eng_content1">
                                {{translate.currentLang=='en'?currentStudent.next.BusStop.name:currentStudent.next.BusStop.name_chinese}}
                            </span>
                        </div>
                        <div *ngIf="currentStudent.next.price">
                            <span style="float:right;font-size: 30px;">
                                {{currentStudent.next.price | currency:'HKD'}}
                            </span>
                        </div>
                        <div fxFlex fxLayout="column" class="Steppercontent-margintop">
                            <div fxFlex="100%" fxLayout="row" *ngIf="!currentStudent.next.price" style="margin-top: 15px;">
                                <div class="step-fab" [ngClass]="{'inactive-background': !(getPaymentStepper(currentStudent.next.PaymentRecords)==1)}">
                                    <div class="step-fabno">
                                        <span style="margin:auto;">!</span>
                                    </div>
                                </div>
                                <div style="margin-top: 5px;margin-bottom: 5px;" class="step-info" [ngClass]="{'inactive-font': !(getPaymentStepper(currentStudent.next.PaymentRecords)==1)}" translate>DASHBOARD.WAITING_FOR_QUOTE</div>
                            </div>
                            <div fxFlex fxLayout="row" *ngIf="currentStudent.next.price" style="margin-top: 15px;">
                                <div class="step-fab" [ngClass]="{'inactive-background': !(getPaymentStepper(currentStudent.next.PaymentRecords)==1)}">
                                    <div class="step-fabno">
                                        <span style="margin:auto;">1</span>
                                    </div>
                                </div>
                                <div class="step-info" [ngClass]="{'inactive-font': !(getPaymentStepper(currentStudent.next.PaymentRecords)==1)}" translate>DASHBOARD.PLEASE_UPLOAD</div>
                            </div>
                            <div fxFlex fxLayout="row" *ngIf="currentStudent.next.price" style="margin-top: 15px;">
                                <div class="step-fab" [ngClass]="{'inactive-background':  !(getPaymentStepper(currentStudent.next.PaymentRecords)==2)}">
                                    <div class="step-fabno">
                                        <span style="margin:auto;">2</span>
                                    </div>
                                </div>
                                <div class="step-info" [ngClass]="{'inactive-font':  !(getPaymentStepper(currentStudent.next.PaymentRecords)==2)}" translate>DASHBOARD.WAITING_CONFIRMATION</div>
                            </div>
                            <div class="button_margintop">
                                <button *ngIf="!currentPaymentRecord && currentStudent.next.price" (click)="gotoPayment()" fxFlex mat-raised-button color="accent">{{ 'DASHBOARD.UPLOAD_PAYMENT_SLIP' | translate }}</button>
                                <button *ngIf="currentPaymentRecord  && currentStudent.next.price" (click)="gotoPayment()" fxFlex mat-raised-button color="accent">{{ 'DASHBOARD.EDIT_PAYMENT_SLIP' | translate }}</button>
                            </div>
                        </div>
                    </div>
                    <span *ngIf="currentStudent.current && currentStudent.current.TransportRecords.length" class="greentextcontent heading-margin" translate>
                        DASHBOARD.LATEST_RECORD
                    </span>
                    <!-- <div *ngIf="currentStudent.current && currentStudent.current.TransportRecords.length" class="information-block" fxFlex fxLayout="column">
                        <span style="float:left;" class="poppinsR">{{ 'DASHBOARD.FOR' | translate }} {{translate.currentLang=='en'?currentStudent.current.Term.name:currentStudent.current.Term.name_chinese}}</span>
                    </div> -->
                    <div style="width: auto;padding: 20px;" fxLayout="column" *ngIf="(currentStudent && currentStudent.current && !currentStudent.current.TransportRecords.length)||(currentStudent && !currentStudent.current && currentStudent.next)">
                        <span class="poppinsM" translate>DASHBOARD.NO_TRANSPORT_RECORD</span>
                    </div>
                    <div  *ngIf="currentStudent.current && currentStudent.current.TransportRecords.length" class="information-block" fxFlex fxLayout="column">
                        <span style="float:left;" class="poppinsR">{{ 'DASHBOARD.FOR' | translate }} {{translate.currentLang=='en'?currentStudent.current.Term.name:currentStudent.current.Term.name_chinese}}</span>
                
                <div fxFlex fxLayout="column" class="Steppercontent-margintop" style="font-family:poppinsB;">
                    <div fxFlex fxLayout="row" class="transpottime" style="border-bottom: 2px gray solid;">
                        <div fxFlex fxLayout="50%">
                            <span style="color:#1b9d77;margin:auto" translate>DASHBOARD.TIME</span>
                        </div>
                        <div fxFlex fxLayout="50%">
                            <span style="color:#1b9d77;margin:auto" translate>DASHBOARD.ROUTE</span>
                        </div>
                    </div>
                    <div fxFlex fxLayout="row" class="transpottime" style="font-family:poppinsR;" >
                        <div fxFlex fxLayout="50%">
                            <span style="margin:auto" *ngIf="currentStudent.current.TransportRecords[0]">{{moment(currentStudent.current.TransportRecords[0].checkin).format('h:mm a')}}</span>
                        </div>
                        <div fxFlex fxLayout="50%">
                            <span style="margin:auto" *ngIf="currentStudent.current.TransportRecords[0]">{{currentStudent.current.TransportRecords[0].Route.name}}</span>
                        </div>
                    </div>
                    <div fxFlex *ngIf="currentStudent.current.TransportRecords[1]" class="transpottime" fxLayout="row" style="border-top: 2px gray dotted; font-family:poppinsR;">
                        <div fxFlex fxLayout="50%">
                            <span style="margin:auto">{{moment(currentStudent.current.TransportRecords[1].checkin).format('h:mm a')}}</span>
                        </div>
                        <div fxFlex fxLayout="50%">
                            <span style="margin:auto">{{currentStudent.current.TransportRecords[1].Route.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
                    <mat-divider fxFlex style="margin-top:20px;margin-bottom:20px;border-top: 1px block solid;" [inset]="true"></mat-divider>
                    <div class="poppinsR">{{ 'DASHBOARD.CHANGE_ROUTE1' | translate }}<span (click)="openContactUsDialog()" style="color:blue;text-decoration: underline;cursor:pointer;">{{ 'DASHBOARD.CHANGE_ROUTE2' | translate }}</span> {{ 'DASHBOARD.CHANGE_ROUTE3' | translate }}<br>{{ 'DASHBOARD.CHANGE_ROUTE4' | translate }}</div>
                </div>
                <div style="width: auto;padding: 20px;" fxLayout="column" *ngIf="!currentStudent">
                    <span class="poppinsM">{{ 'DASHBOARD.CREATE_STUDENT1' | translate }}<span (click)="createStudent()" style="color:#2c88d0;text-decoration: underline;cursor:pointer;">{{ 'DASHBOARD.CREATE_STUDENT2' | translate }}</span>{{ 'DASHBOARD.CREATE_STUDENT3' | translate }}</span>
                </div>
            </div>
        </mat-card>
    </div>
</div>
<!-- Mobile -->
<div fxHide fxShow.lt-sm fxLayout="column">
    <div fxFlex fxLayout="column" >
        <mat-card class="block" fxLayout="column">
            <div class="block-top">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">space_dashboard</mat-icon>
                </div>
                <div class="title" translate>
                    DASHBOARD.DASHBOARD
                </div>
            </div>
            <div fxLayout="column" style="margin-top: 10px;">
                <span style="font-size:20px; color:#1b9d77;" translate>DASHBOARD.REGISTERED_STUDENTS</span>
                <div *ngFor="let student of students" class="student-button" [ngClass]="{'selected': student==currentStudent}" (click)="currentStudent = student">
                    {{student.current?student.current.name:student.next.name}}
                </div>
                <div style="margin:20px 0; border-top: 1px solid black;"></div>
                <button mat-raised-button color="accent" *ngIf="(this.currentStudent && this.currentStudent.current) || (this.currentStudent && this.currentStudent.next)" (click)="editStudent()">{{ 'DASHBOARD.EDIT_STUDENT' | translate }}</button>
            </div>
            <div *ngIf="currentStudent" fxLayout="column">
                  <ng-template mat-tab-label class="eng_content1" style="position:relative;">
                    <span class="greentextcontent">{{currentStudent.current?currentStudent.current.name:currentStudent.next.name}}</span>
                  </ng-template>
                    <button style="min-height:108px;" *ngIf="currentStudent.current && currentStudent.current.status=='Active' && terms && terms[0].status=='Next' && terms[0].open &&!currentStudent.next" fxFlex mat-raised-button color="accent" (click)="continueStudent(currentStudent.current.id)">{{ 'DASHBOARD.REGISTER_NEXT_TERM' | translate }}</button>
                    <span *ngIf="currentStudent.current && (currentStudent.current.status=='Pending'||currentStudent.current.status=='Quote')" class="greentextcontent" style="margin-top: 20px;" translate>DASHBOARD.PENDING_PAYMENT</span>
                    <div class="information-block" fxFlex fxLayout="column" *ngIf="currentStudent.current && (currentStudent.current.status=='Pending'||currentStudent.current.status=='Quote')">
                        <div>
                            <span class="eng_content1" style="color: #1b9d77;">
                                {{translate.currentLang=='en'?currentStudent.current.Term.name:currentStudent.current.Term.name_chinese}}
                            </span>
                        </div>
                        <div>
                            <span class="eng_content2">
                                {{currentStudent.current.name}}
                            </span>
                        </div>
                        <div>
                            <span class="eng_content1">
                                {{ 'DASHBOARD.ROUTE' | translate }} {{currentStudent.current.BusStop.Route.name}}
                            </span>
                        </div>
                        <div>
                            <span class="eng_content1">
                                {{translate.currentLang=='en'?currentStudent.current.BusStop.name:currentStudent.current.BusStop.name_chinese}}
                            </span>
                        </div>

                        <div *ngIf="currentStudent.current.price">
                            <span style="float:right;font-size: 30px;">
                                {{currentStudent.current.price | currency:'HKD'}}
                            </span>
                        </div>
                        <div fxFlex fxLayout="column">
                            <div fxFlex fxLayout="row" *ngIf="!currentStudent.current.price" style="margin-top: 15px;">
                                <div class="step-fab" [ngClass]="{'inactive-background': !(getPaymentStepper(currentStudent.current.PaymentRecords)==1)}">
                                    <div class="step-fabno">
                                        <span style="margin:auto;">!</span>
                                    </div>
                                </div>
                                <div class="step-info" [ngClass]="{'inactive-font': !(getPaymentStepper(currentStudent.current.PaymentRecords)==1)}" translate>DASHBOARD.WAITING_FOR_QUOTE</div>
                            </div>
                            <div fxFlex fxLayout="row" *ngIf="currentStudent.current.price" style="margin-top: 15px;">
                                <div class="step-fab" [ngClass]="{'inactive-background': !(getPaymentStepper(currentStudent.current.PaymentRecords)==1)}">
                                    <div class="step-fabno">
                                        <span style="margin:auto;">1</span>
                                    </div>
                                </div>
                                <div class="step-info" [ngClass]="{'inactive-font': !(getPaymentStepper(currentStudent.current.PaymentRecords)==1)}" translate>DASHBOARD.PLEASE_UPLOAD</div>
                            </div>
                            <div fxFlex fxLayout="row" *ngIf="currentStudent.current.price" style="margin-top: 15px;">
                                <div class="step-fab" [ngClass]="{'inactive-background':  !(getPaymentStepper(currentStudent.current.PaymentRecords)==2)}">
                                    <div class="step-fabno">
                                        <span style="margin:auto;">2</span>
                                    </div>
                                </div>
                                <div class="step-info" [ngClass]="{'inactive-font':  !(getPaymentStepper(currentStudent.current.PaymentRecords)==2)}" translate>DASHBOARD.WAITING_CONFIRMATION</div>
                            </div>
                            <div class="button_margintop">
                                <button *ngIf="!currentPaymentRecord && currentStudent.current.price" (click)="gotoPayment()" fxFlex mat-raised-button color="accent">{{ 'DASHBOARD.UPLOAD_PAYMENT_SLIP' | translate }}</button>
                                <button *ngIf="currentStudent.current.price && currentPaymentRecord" (click)="gotoPayment()" fxFlex mat-raised-button color="accent">{{ 'DASHBOARD.EDIT_PAYMENT_SLIP' | translate }}</button>
                            </div>    
                        </div>
                    </div>
                    <span *ngIf="currentStudent.next && (currentStudent.next.status=='Pending'||currentStudent.next.status=='Quote')" class="greentextcontent" style="margin-top: 20px;" translate>DASHBOARD.PENDING_PAYMENT</span>
                    <div class="information-block" fxFlex fxLayout="column" *ngIf="currentStudent.next && (currentStudent.next.status=='Pending'||currentStudent.next.status=='Quote')">
                        <div>
                            <span class="eng_content1" style="color: #1b9d77;">
                                {{translate.currentLang=='en'?currentStudent.next.Term.name:currentStudent.next.Term.name_chinese}}
                            </span>
                        </div>
                        <div>
                            <span class="eng_content2">
                                {{currentStudent.next.name}}
                            </span>
                        </div>
                        <div>
                            <span class="eng_content1">
                                {{ 'DASHBOARD.ROUTE' | translate }} {{currentStudent.next.BusStop.Route.name}}
                            </span>
                        </div>
                        <div>
                            <span class="eng_content1">
                                {{translate.currentLang=='en'?currentStudent.next.BusStop.name:currentStudent.next.BusStop.name_chinese}}
                            </span>
                        </div>
                        <div *ngIf="currentStudent.next.price">
                            <span style="float:right;font-size: 30px;">
                                {{currentStudent.next.price | currency:'HKD'}}
                            </span>
                        </div>
                        <div fxFlex fxLayout="column" class="Steppercontent-margintop">
                            <div fxFlex fxLayout="row" *ngIf="!currentStudent.next.price">
                                <div class="step-fab" [ngClass]="{'inactive-background': !(getPaymentStepper(currentStudent.next.PaymentRecords)==1)}">
                                    <div class="step-fabno">
                                        <span style="margin:auto;">!</span>
                                    </div>
                                </div>
                                <div class="step-info" [ngClass]="{'inactive-font': !(getPaymentStepper(currentStudent.next.PaymentRecords)==1)}" translate>DASHBOARD.WAITING_FOR_QUOTE</div>
                            </div>
                            <div fxFlex fxLayout="row" *ngIf="currentStudent.next.price">
                                <div class="step-fab" [ngClass]="{'inactive-background': !(getPaymentStepper(currentStudent.next.PaymentRecords)==1)}">
                                    <div class="step-fabno">
                                        <span style="margin:auto;">1</span>
                                    </div>
                                </div>
                                <div class="step-info" [ngClass]="{'inactive-font': !(getPaymentStepper(currentStudent.next.PaymentRecords)==1)}" translate>DASHBOARD.PLEASE_UPLOAD</div>
                            </div>
                            <div fxFlex fxLayout="row" *ngIf="currentStudent.next.price">
                                <div class="step-fab" [ngClass]="{'inactive-background':  !(getPaymentStepper(currentStudent.next.PaymentRecords)==2)}">
                                    <div class="step-fabno">
                                        <span style="margin:auto;">2</span>
                                    </div>
                                </div>
                                <div class="step-info" [ngClass]="{'inactive-font':  !(getPaymentStepper(currentStudent.next.PaymentRecords)==2)}" translate>DASHBOARD.WAITING_CONFIRMATION</div>
                            </div>
                            <div class="button_margintop">
                                <button *ngIf="!currentPaymentRecord && currentStudent.next.price" (click)="gotoPayment()" fxFlex mat-raised-button color="accent">{{ 'DASHBOARD.UPLOAD_PAYMENT_SLIP' | translate }}</button>
                                <button *ngIf="currentPaymentRecord && currentStudent.next.price" (click)="gotoPayment()" fxFlex mat-raised-button color="accent">{{ 'DASHBOARD.EDIT_PAYMENT_SLIP' | translate }}</button>
                            </div>
                        </div>
                    </div>
                    <span style="float:left;" *ngIf="currentStudent.current && currentStudent.current.TransportRecords.length" class="greentextcontent heading-margin" translate>DASHBOARD.LATEST_RECORD</span>
                <div *ngIf="currentStudent.current && currentStudent.current.TransportRecords.length" class="information-block" fxFlex fxLayout="column">
                            <span style="float:left;" class="poppinsR">{{ 'DASHBOARD.FOR' | translate }} {{translate.currentLang=='en'?currentStudent.current.Term.name:currentStudent.current.Term.name_chinese}}</span>
                    
                    <div fxFlex fxLayout="column" class="Steppercontent-margintop" style="font-family:poppinsB;">
                        <div fxFlex fxLayout="row" class="transpottime" style="border-bottom: 2px gray solid;">
                            <div fxFlex fxLayout="50%">
                                <span style="color:#1b9d77;margin:auto" translate>DASHBOARD.TIME</span>
                            </div>
                            <div fxFlex fxLayout="50%">
                                <span style="color:#1b9d77;margin:auto" translate>DASHBOARD.ROUTE</span>
                            </div>
                        </div>
                        <div fxFlex fxLayout="row" class="transpottime" style="font-family:poppinsR;">
                            <div fxFlex fxLayout="50%">
                                <span style="margin:auto" *ngIf="currentStudent.current.TransportRecords[0]">{{moment(currentStudent.current.TransportRecords[0].checkin).format('h:mm a')}}</span>
                            </div>
                            <div fxFlex fxLayout="50%">
                                <span style="margin:auto" *ngIf="currentStudent.current.TransportRecords[0]">{{currentStudent.current.TransportRecords[0].Route.name}}</span>
                            </div>
                        </div>
                        <div fxFlex *ngIf="currentStudent.current.TransportRecords[1]" class="transpottime" fxLayout="row" style="border-top: 2px gray dotted; font-family:poppinsR;">
                            <div fxFlex fxLayout="50%">
                                <span style="margin:auto">{{moment(currentStudent.current.TransportRecords[1].checkin).format('h:mm a')}}</span>
                            </div>
                            <div fxFlex fxLayout="50%">
                                <span style="margin:auto">{{currentStudent.current.TransportRecords[1].Route.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: auto;padding: 20px;" fxLayout="column" *ngIf="(currentStudent && currentStudent.current && !currentStudent.current.TransportRecords.length)||(currentStudent && !currentStudent.current && currentStudent.next)">
                <span class="poppinsM" translate>DASHBOARD.NO_TRANSPORT_RECORD</span>
            </div>
            <mat-divider fxFlex style="margin-top:20px;margin-bottom:20px;border-top: 1px block solid;" [inset]="true"></mat-divider>
            <button fxFlex mat-raised-button color="accent" (click)="createStudent()">{{ 'DASHBOARD.CREATE_STUDENT' | translate }}</button>
            <button fxFlex mat-raised-button color="accent" (click)="paymentHistory()" style="margin-top: 15px;">{{ 'DASHBOARD.PAYMENT_HISTORY' | translate }}</button>
            <div class="poppinsR content-margintop">{{ 'DASHBOARD.CHANGE_ROUTE1' | translate }}<span (click)="openContactUsDialog()" style="color:blue;text-decoration: underline;cursor:pointer;">{{ 'DASHBOARD.CHANGE_ROUTE2' | translate }}</span><span [innerHTML]="'DASHBOARD.CHANGE_ROUTE3' | translate"></span><br><span [innerHTML]="'DASHBOARD.CHANGE_ROUTE4' | translate"></span></div>
        </mat-card>
    </div>
</div>