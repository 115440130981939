import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment'

import { Notification } from '../types'

interface NotificationS extends Notification{
  showMore: boolean;
}

@Component({
  selector: 'app-log-in-page',
  templateUrl: './log-in-page.component.html',
  styleUrls: ['./log-in-page.component.css']
})
export class LogInPageComponent implements OnInit {
  constructor(private apiService: ApiService, private loginService: LoginService, private router: Router, public translate: TranslateService) {}

  public news:NotificationS[] = [];
  username: string="";
  password: string="";
  loginError: boolean = false;
  maintenance: any = { online : 'True'};
  public moment = moment;
  ngOnInit(): void {
    const token = localStorage.getItem('id_token');
    const expires_at = localStorage.getItem('expires_at');
    this.apiService.getMaintenance().subscribe(maintenance=>{
      this.maintenance = maintenance;
    })
    if(expires_at){
      if(!moment().isBefore(moment(expires_at))){
        this.loginService.logout()
      }else{
        this.router.navigate(['/dashboard'])
      }
    }
    this.apiService.getNews().subscribe(news=>{
      news.forEach((n:any)=>{
        n.date = moment(n.date);
        n.showMore = false;
      })
      this.news = news;
    })
  }

  login(): void {
    if(this.maintenance.online!='false'){
      this.loginService.login(this.username, this.password).subscribe(response=>{
        if(response.status == true){
          this.loginService.setSession(response.token, response.expiresIn)
          if(response.language_preference == 'English'){
            this.translate.use('en');
            localStorage.setItem('language','en');
          }else{
            this.translate.use('zh');
            localStorage.setItem('language','zh');
          }
          this.router.navigate(['/dashboard'])
        }else{
          this.loginError = true;
        }
        // this.setSession()
      })
    }
  }
}
