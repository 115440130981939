import { Component, OnInit, ViewChild } from '@angular/core';
import {FormGroup, FormControl, Validators, AbstractControl, ValidatorFn} from '@angular/forms';
import { ApiService } from '../api.service';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.css']
})

export class RegistrationPageComponent implements OnInit {
  // firstFormGroup: FormGroup;
  registrationFormGroup: FormGroup = <FormGroup>{}
  confirmedPassword:any;
  recaptcha: string = "";
  step: number = 0;
  mobile: string= "";
  code: string= "";
  codeErrorMassage: boolean= false;
  formErrorMassage: string="";
  countdown: number= 0
  agreeTerms: boolean = false
  constructor(private apiService: ApiService, private router: Router, private translate: TranslateService) {
  }

  ngOnInit() {
    this.registrationFormGroup = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      name: new FormControl('', [
        Validators.required,
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      cpassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        // (): ValidatorFn  =>{ 
        //   return (control: AbstractControl): {[key: string]: any} | null => {
        //       if(control.value === this.registrationFormGroup.controls.password.value){
        //       if (false){
        //         return null;
        //       }else{
        //         return {differentPassword: {value: control.value}};;
        //       }
        //     }
        // }
      ]),
    })
  }

  startCountDown():void{
    this.countdown = 180;
    setInterval(()=>{ 
      if(this.countdown!==0)
      {this.countdown-=1;
      }
         }, 
      1000);
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  letterOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57)||(charCode >= 65 && charCode <= 90)||(charCode >= 97 && charCode <= 122)) {
      return true;
    }
    return false;
  }

  completeRecaptcha(event: string){
    this.recaptcha = event;
    this.sendVerificationCode();
  }

  sendVerificationCode(){
    this.startCountDown()
    this.apiService.sendVerificationCode(this.mobile, this.recaptcha).subscribe(result=>{
      if(result.result=true){
        this.step = 1;
      }
    })
  }

  verifyCode(){
    this.apiService.verifyCode(this.mobile, this.code).subscribe(result=>{
      if(result.result==true){
        this.countdown=0;
        this.step = 2;
      }else{
        this.codeErrorMassage = true;
        this.code=""
      }
    })
  }

  submitForm(){
    this.step = 3;
    this.formErrorMassage = ""
    if(this.registrationFormGroup.controls.password.value!==this.registrationFormGroup.controls.cpassword.value){
      if(this.translate.currentLang == 'en'){
        this.formErrorMassage += "Passwords are not same<br>"
      }else{
        this.formErrorMassage += "密碼不一致<br>"
      }
    }
    this.apiService.checkUsername(this.registrationFormGroup.controls.username.value).subscribe(res=>{
      if(!res.result){
        if(this.translate.currentLang == 'en'){
          this.formErrorMassage += "Username in use.<br>"
        }else{
          this.formErrorMassage += "用戶名已被使用<br>"
        }
        this.step = 2;      
      }else if(this.formErrorMassage == "" && this.registrationFormGroup.status == "VALID"){
        this.apiService.memberRegistration(this.registrationFormGroup.controls.name.value,this.registrationFormGroup.controls.username.value,this.registrationFormGroup.controls.password.value,this.registrationFormGroup.controls.email.value,this.mobile, this.code).subscribe(res=>{
          if(res.result){
            this.router.navigate(['/log-in']);
          }else{
            if(this.translate.currentLang == 'en'){
              this.formErrorMassage += "Server Error. Please try again later.<br>"
            }else{
              this.formErrorMassage += "伺服器錯誤，請稍後再重試。<br>"
            }
          }
        })
      }else{
        this.step = 2;
      }
    })
  }

}
