import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { AuthGuard } from './services/authguard.service';

import { LogInPageComponent } from './log-in-page/log-in-page.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { ForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { DashboardComponent } from './dashboard/dashboard.component'
import { StudentComponent } from './student/student.component'
import { PaymentComponent } from './payment/payment.component'
import { ProfileComponent } from './profile/profile.component'
import { InboxComponent } from './inbox/inbox.component';
import { RouteListComponent } from './route-list/route-list.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';

const routes: Routes = [
  { path: '', component: LogInPageComponent, pathMatch: 'full' },
  { path: 'log-in', component: LogInPageComponent },
  { path: 'registration', component: RegistrationPageComponent },
  { path: 'forgot-password', component: ForgotPasswordPageComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'create-student', component: StudentComponent, canActivate: [AuthGuard] },
  { path: 'edit-student/:studentId', component: StudentComponent, canActivate: [AuthGuard] },
  { path: 'continue-student/:studentId', component: StudentComponent, canActivate: [AuthGuard] },
  { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard] },
  { path: 'payment-history', component: PaymentHistoryComponent, canActivate: [AuthGuard] },
  // { path: 'payment/:paymentRecordId', component: PaymentComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'inbox', component: InboxComponent, canActivate: [AuthGuard] },
  { path: 'route-list', component: RouteListComponent},
  { path: '**' , redirectTo: 'log-in'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    {
      anchorScrolling: 'enabled',
      initialNavigation: 'enabled',
      onSameUrlNavigation: 'reload'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
