import { Component, OnInit } from '@angular/core';
import * as _ from "lodash";
import { ApiService }  from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../types';
import * as moment from 'moment';

interface NotificationS extends Notification{
  showMore: boolean;
}

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})

export class InboxComponent implements OnInit {
  public moment = moment;
  seenFilter = "Unseen"
  importantChip = true
  newsChip = true
  systemChip = true

  notifications:NotificationS[] = [];
  constructor(private apiService: ApiService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.apiService.getNotifications().subscribe(res=>{
      this.notifications = res.notifications
      this.notifications.forEach(n=>{
        n.showMore = false
      })
    })
  }

  markAsSeen(notificationId:number): void{
    this.apiService.updateNotificationStatus(notificationId, "Seen").subscribe(res=>{
      let index = _.findIndex(this.notifications, {id: notificationId});
      // console.log(this.notifications[index])
      this.notifications[index].MemberNotification.status = "Seen"
    })
  }

  markAsUnseen(notificationId:number): void{
    this.apiService.updateNotificationStatus(notificationId, "Unseen").subscribe(res=>{
      let index = _.findIndex(this.notifications, {id: notificationId});
      this.notifications[index].MemberNotification.status = "Unseen"
    })
  }

}
