import { Component, OnInit, Inject } from '@angular/core';
import { ApiService }  from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import * as moment from 'moment';
import * as _ from 'lodash';

import { Notification } from '../types';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  students:any = [];
  terms: any;
  currentStudent:any; 
  currentPaymentRecord:any;
  moment = moment;
  //students = [{current:{}, next:{}}]
  constructor(public dialog: MatDialog, private apiService: ApiService, private router: Router, public translate: TranslateService) {
      apiService.getStudents().subscribe(res=>{
        // this.students = res;
        res.forEach((student:any)=>{
          if(student.Term.status=='Current'){
            this.students.push({current: student, next: null})
          }else{
            if(_.find(this.students,{current:{id:student.previouId}})){
              _.find(this.students,{current:{id:student.previouId}}).next = student
            }else{
              this.students.push({current: null, next: student})
            }
          }
          console.log(this.students);
        })
        this.currentStudent = this.students[0]
        console.log(this.students)
      })
      apiService.getLatestPaymentRecord().subscribe(res=>{
        if(res.paymentRecord && res.paymentRecord.status=='Waiting'){
          this.currentPaymentRecord = res.paymentRecord
        }
      })
  }

  ngOnInit(): void {//TODO: need something to indicate the student has been registered for next term
    this.apiService.getAvailableTerms().subscribe(res=>{
      this.terms = res
    })
    // this.apiService.getNewestNotifications().subscribe(res=>{
    //   if(res.result && res.notifications.length){
    //     const dialogRef = this.dialog.open(InboxDialog, {
    //       width: '80%',
    //       maxWidth: '550px',
    //       minWidth: '300px',
    //       // height: '80%',
    //       data: {
    //         notifications : res.notifications,
    //       }
    //     });
    
    //     dialogRef.afterClosed().subscribe(result => {
    //       // if(typeof(result)=='undefined'){
    //       //   this.reload()
    //       // }
    //     });
    //   }
    // })
  }

  gotoPayment(id=null){
    console.log(id)
    if(id==null){
      this.router.navigate(["payment"]);
    }else{
      this.router.navigate(["payment",id]);
    }
  }

  openContactUsDialog(){
    const dialogRef = this.dialog.open(ContactUsDialog, {
      width: '300px',
      // height: '80%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
      // if(typeof(result)=='undefined'){
      //   this.reload()
      // }
    });

  }

  createStudent(): void {
    this.router.navigate(['create-student'])
  }

  continueStudent(id:number): void{
    this.router.navigate(["continue-student",id]);
  }

  paymentHistory(): void {
    this.router.navigate(['payment-history'])
  }


  editStudent(): void{

    if(this.currentStudent && this.currentStudent.current){
      this.router.navigate(["edit-student",this.currentStudent.current.id]);
    }else{
      this.router.navigate(["edit-student",this.currentStudent.next.id]);
    }
  }

  getPaymentStepper(paymentRecords:any):number{ // 0 = quote, 1=pending, 2=waiting 
    if(!paymentRecords.length){
      return 1;
    }
    if(paymentRecords.length && (paymentRecords[paymentRecords.length-1].status=='Quote')){
      return 0;
    }
    if(paymentRecords.length && (paymentRecords[paymentRecords.length-1].status=='Waiting')){
      return 2;
    }
    if(paymentRecords.length && (paymentRecords[paymentRecords.length-1].status=='Pending')){
      return 1;
    }
    if(paymentRecords.length && (paymentRecords[paymentRecords.length-1].status=='Rejected')){
      return 1;
    }
    if(paymentRecords.length && (paymentRecords[paymentRecords.length-1].status=='Cancelled')){
      return 1;
    }
    return 2
  }
}

@Component({
  selector: 'inbox-dialog',
  templateUrl: 'inbox-dialog.html',
  styleUrls: ['./inbox-dialog.css']
})
export class InboxDialog {
  moment = moment;
  notifications: Notification[] = []
  index = 0;
  checked = true
  showMore = false
  constructor(
    public dialogRef: MatDialogRef<InboxDialog>,
    private apiService: ApiService, 
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.notifications = data.notifications
    console.log(this.notifications)
  }

  onNext(): void{
    if(this.checked==true){
      this.apiService.updateNotificationStatus(this.notifications[this.index].id, "Seen").subscribe(res=>{
        if(this.index == this.notifications.length-1){
          this.dialogRef.close();
        }else{
          this.index++
        }
      })
    }else{
      if(this.index == this.notifications.length-1){
        this.dialogRef.close();
      }else{
        this.index++
      }
    }
  }
}

@Component({
  selector: 'contact-us-dialog',
  templateUrl: 'contact-us-dialog.html',
  styleUrls: ['./contact-us-dialog.css']
})
export class ContactUsDialog {
  constructor(
    public dialogRef: MatDialogRef<ContactUsDialog>,
    private apiService: ApiService, 
    public translate: TranslateService,
  ) {
  }

  closeDialog(){
    this.dialogRef.close();
  }
}


