<div fxFlex fxLayout="column" style="position: relative;height: 100%;">
    <div class="noticeboard" fxFlex fxLayout="column">
        <span style="color: white; font-size: 30px" translate>INBOX_POP.IMPORTANT</span>
        <span style="color: white; font-size: 60px;" translate>INBOX_POP.NEWS</span>
    </div>
    <div style="padding:20px 20px 0px 20px;" fxLayout="row">
        <span style="padding-top:5px; font-family: poppinsB;">{{moment(notifications[index].date).format('DD/MM/YYYY')}}</span>
        <mat-chip-list style="margin-left: auto; font-family: poppinsR; color:white;">
            <mat-chip *ngIf="notifications[index].type=='Important'" style="background-color: rgb(255, 0, 0); color: white;">{{translate.currentLang=='en'?'Important':'重要'}}</mat-chip>
            <mat-chip *ngIf="notifications[index].type=='System'">{{translate.currentLang=='en'?'System':'系統'}}</mat-chip>
        </mat-chip-list>
    </div>
    <div *ngIf="!showMore" class="content-box" [innerHTML]="translate.currentLang=='en'?notifications[index].content:notifications[index].content_chinese">
    </div>
    <div *ngIf="showMore" class="content-box-show-more" [innerHTML]="translate.currentLang=='en'?notifications[index].content:notifications[index].content_chinese">
    </div>
    <div (click)="showMore=!showMore" *ngIf="!showMore" class="show-button">{{'INBOX_POP.SHOW_MORE'|translate}}</div>
    <div (click)="showMore=!showMore" *ngIf="showMore" class="show-button">{{'INBOX_POP.SHOW_LESS'|translate}}</div>
    <div fxLayout="column" style="margin-top: 10px; bottom: 0;padding: 20px;width:100%">
        <mat-checkbox style="float:right;" [(ngModel)]="checked"><div class="poppinsR" style="font-size: 12px;">{{'INBOX_POP.MARK_AS'|translate}}</div></mat-checkbox>
        <button *ngIf="index!==notifications.length-1" (click)="onNext()" fxFlex mat-raised-button color="primary">{{'INBOX_POP.NEXT'|translate}}</button>
        <button *ngIf="index==notifications.length-1" (click)="onNext()" fxFlex mat-raised-button color="primary">{{'INBOX_POP.CLOSE'|translate}}</button>
    </div>
</div>