import { Component, OnInit } from '@angular/core';
import { ApiService }  from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from "lodash";
import { switchAll } from 'rxjs/operators';
import * as moment from "moment";

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {
  public filter:string = "All"
  constructor(private apiService:ApiService, public translate: TranslateService) { }
  paymentRecordsBk: any =[];
  paymentRecords: any =[];
  public moment = moment;
  ngOnInit(): void {
    this.apiService.getPyamentHistory().subscribe(res=>{
      this.paymentRecordsBk = res
      this.paymentRecords = res
      this.paymentRecords.forEach((pr:any) => {
        pr.display = false;
      });
    })
  }

  applyFilter(type:string){
    this.filter = type;
    this.paymentRecords = [];
    switch(this.filter){
      case 'All':
        this.paymentRecords = this.paymentRecordsBk;
        break;
      case 'Approved':
        for(var a=0;a<this.paymentRecordsBk.length;a++){
          if(this.paymentRecordsBk[a].status == 'Approved'){
            this.paymentRecords.push(this.paymentRecordsBk[a])
          }
        }
        break;
      case 'Rejected':
        for(var a=0;a<this.paymentRecordsBk.length;a++){
          if(this.paymentRecordsBk[a].status == 'Rejected'){
            this.paymentRecords.push(this.paymentRecordsBk[a])
          }
        }
        break;
    }
  }
}
