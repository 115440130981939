<mat-card fxShow fxHide.lt-sm class="whiteboardA">
    <div class="block">
      <div class="block-top">
        <div class="block-label">
            <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">how_to_reg</mat-icon>
        </div>
        <div class="title" translate>
          REGISTRATION.REGISTRATION
        </div>
      </div>  
        <div class="content" fxLayout="column">
          <div fxFlex [ngClass]="{'disabled':(step==-1)||(step>=2)}">
            <div *ngIf="!(step<=1)" class="step"><mat-icon>done</mat-icon></div><div class="step" *ngIf="step<=1">1</div><span style='margin-left:10px;' translate>REGISTRATION.PHONE_REGISTRATION</span>
          </div>
          <span class="Steppercontent-margintop poppinsR" translate>REGISTRATION.PHONE_DESCRIPTION</span>
          <div *ngIf="step==0 || step ==1" fxFlex fxLayout="column" class="Steppercontent-margintop">
            <mat-form-field appearance="fill" *ngIf="countdown==0">
              <mat-label translate>REGISTRATION.PHONE_NUMBER</mat-label>
              <input (keypress)="numberOnly($event)" [(ngModel)]="mobile" matInput placeholder="{{ 'REGISTRATION.PHONE_NUMBER' | translate }}" required>
            </mat-form-field>
            <!-- <div style="font-size:12px" *ngIf="countdown==0" translate>REGISTRATION.INVALID_PHONE_NUMBER</div> -->
            <div class="Steppercontent-margintop" *ngIf="countdown==0 && mobile">
              <re-captcha 
                (resolved)="completeRecaptcha($event)"
                siteKey="6LcIsVgaAAAAAPTm2FzktFm5D_fM_XjZq1yc4Kn-"
              ></re-captcha>
            </div>
            <!-- <div class="Steppercontent-margintop" *ngIf="countdown==0">
              <button mat-raised-button color="accent" (click)="sendVerificationCode()" [disabled]="(mobile==='') || (mobile.length <8) || recaptcha==''">{{ 'REGISTRATION.SEND_CODE' | translate }}</button>
            </div> -->
            <div *ngIf="step==1">
              <div class="stepperdes" *ngIf="countdown!=0" [translate]="'REGISTRATION.COOLTIME'" [translateParams]="{second: countdown}">
              </div>
              <div translate>
                REGISTRATION.ENTER_CODE
              </div>
              <mat-form-field appearance="fill" class="steppertitle-margintop">
                  <mat-label translate>REGISTRATION.CODE</mat-label>
                  <input [(ngModel)]="code" (keypress)="numberOnly($event)" matInput placeholder="{{ 'REGISTRATION.CODE' | translate }}" required>
              </mat-form-field>
              <br>
              <span *ngIf="codeErrorMassage" style="color:red;" translate>REGISTRATION.INVALID_CODE</span>
              <div>
                  <button (click)="verifyCode()" mat-raised-button color="accent" [disabled]="code.length<6">{{ 'REGISTRATION.VERIFY_PHONE' | translate }}</button>
              </div>
            </div>
          </div>
          <div class="steppertitle-margintop" fxFlex fxLayout="row" [ngClass]="{'disabled':step<2}">
            <div class="step">2</div><span style='margin-left:10px;' translate>REGISTRATION.FILL_IN</span>
          </div>
          <div *ngIf="step>=2" fxFlex fxLayout="row">
            <form [formGroup]="registrationFormGroup">
              <mat-form-field class="Steppercontent-margintop" appearance="fill">
                  <mat-label translate>REGISTRATION.USERNAME</mat-label>
                  <input (keypress)="letterOnly($event)" matInput placeholder="{{ 'REGISTRATION.USERNAME' | translate }}" formControlName="username" required>
                  <mat-error *ngIf="registrationFormGroup.controls.username.invalid" translate>REGISTRATION.MINIMUM_USERNAME_LENGTH_ERROR</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                  <mat-label translate>REGISTRATION.FORM_NAME</mat-label>
                  <input matInput placeholder="{{ 'REGISTRATION.FORM_NAME' | translate }}" formControlName="name" required>
              </mat-form-field>
              <mat-form-field appearance="fill">
                  <mat-label translate>REGISTRATION.FORM_EMAIL</mat-label>
                  <input matInput placeholder="{{ 'REGISTRATION.FORM_EMAIL' | translate }}" formControlName="email" required>
                  <mat-error *ngIf="registrationFormGroup.controls.email.errors" translate>REGISTRATION.INVALID_MAIL_FORMAT</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                  <mat-label translate>REGISTRATION.FORM_PASSWORD</mat-label>
                  <input (keypress)="letterOnly($event)" type="password" matInput placeholder="{{ 'REGISTRATION.FORM_PASSWORD' | translate }}" formControlName="password" required>
                  <mat-error *ngIf="registrationFormGroup.controls.password.invalid" translate>REGISTRATION.MINIMUM_PASSWORD_LENGTH_ERROR</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill">
                  <mat-label translate>REGISTRATION.FORM_CONFIRM_PASSWORD</mat-label>
                  <input (keypress)="letterOnly($event)" type="password" matInput placeholder="{{ 'REGISTRATION.FORM_CONFIRM_PASSWORD' | translate }}" formControlName="cpassword" required>
                  <mat-error *ngIf="registrationFormGroup.controls.cpassword.invalid" translate>REGISTRATION.MINIMUM_PASSWORD_LENGTH_ERROR</mat-error>
              </mat-form-field>
              <div *ngIf="formErrorMassage!==''" style="color: red;" [innerHTML]="formErrorMassage">
              </div>
              <div>
                  <button [disabled]='step==3' (click)="submitForm()" mat-raised-button color="accent" >{{ 'REGISTRATION.FORM_COMPLETE' | translate }}</button>
              </div>
            </form>
          </div>
        </div>
    </div>
</mat-card>
<mat-card fxHide fxShow.lt-sm>
    <div class="block">
      <div class="block-top">
        <div class="block-label">
            <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">how_to_reg</mat-icon>
        </div>
        <div class="title" translate>
          REGISTRATION.REGISTRATION
        </div>
      </div>  
        <div class="content poppinsR" fxFlex fxLayout="column">
            <div fxFlex fxLayout="row" [ngClass]="{'disabled':(step==-1)||(step>=2)}">
              <div *ngIf="!(step<=1)" class="step"><mat-icon>done</mat-icon></div><div class="step" *ngIf="step<=1">1</div><span style='margin-left:10px;' translate>REGISTRATION.PHONE_REGISTRATION</span>
            </div>
            <span class="Steppercontent-margintop" translate>REGISTRATION.PHONE_DESCRIPTION</span>
            <div *ngIf="step==0 || step ==1" fxFlex fxLayout="column" class="Steppercontent-margintop">
              <mat-form-field appearance="fill" *ngIf="countdown==0">
                <mat-label translate>REGISTRATION.PHONE_NUMBER</mat-label>
                <input (keypress)="numberOnly($event)" [(ngModel)]="mobile" matInput placeholder="{{ 'REGISTRATION.PHONE_NUMBER' | translate }}" required>
              </mat-form-field>
              <!-- <div style="font-size:12px" *ngIf="countdown==0">REGISTRATION.INVALID_PHONE_NUMBER</div> -->
              <div class="Steppercontent-margintop" *ngIf="countdown==0">
                <re-captcha
                  (resolved)="completeRecaptcha($event)"
                  siteKey="6LcIsVgaAAAAAPTm2FzktFm5D_fM_XjZq1yc4Kn-"
                ></re-captcha>
              </div>
              <!-- <div class="Steppercontent-margintop" *ngIf="countdown==0">
                <button mat-raised-button color="accent" (click)="sendVerificationCode()" [disabled]="(mobile==='') || (mobile.length <8) || recaptcha==''">{{ 'REGISTRATION.SEND_CODE' | translate }}</button>
              </div> -->
              <div *ngIf="step==1">
                <div class="stepperdes" *ngIf="countdown!=0" [translate]="'REGISTRATION.COOLTIME'" [translateParams]="{second: countdown}"></div>
                <div translate>
                  REGISTRATION.ENTER_CODE
                </div>
                <mat-form-field appearance="fill" class="steppertitle-margintop">
                    <mat-label translate>REGISTRATION.CODE</mat-label>
                    <input [(ngModel)]="code" (keypress)="numberOnly($event)" matInput placeholder="{{ 'REGISTRATION.CODE' | translate }}" required>
                </mat-form-field>
                <br>
                <span *ngIf="codeErrorMassage" style="color:red;" translate>REGISTRATION.INVALID_CODE</span>
                <div>
                    <button (click)="verifyCode()" mat-raised-button color="accent" [disabled]="code.length<6">{{ 'REGISTRATION.CODE' | translate }}</button>
                </div>
              </div>
            </div>
            <div class="steppertitle-margintop" fxFlex fxLayout="row" [ngClass]="{'disabled':step<2}">
              <div class="step">2</div><span style='margin-left:10px;' translate>REGISTRATION.FILL_IN</span>
            </div>
            <div *ngIf="step>=2" fxFlex fxLayout="row">
              <form [formGroup]="registrationFormGroup">
                <mat-form-field class="Steppercontent-margintop" appearance="fill">
                    <mat-label translate>REGISTRATION.USERNAME</mat-label>
                    <input (keypress)="letterOnly($event)" matInput placeholder="{{ 'REGISTRATION.USERNAME' | translate }}" formControlName="username" required>
                    <mat-error *ngIf="registrationFormGroup.controls.username.invalid" translate>REGISTRATION.MINIMUM_USERNAME_LENGTH_ERROR</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label translate>REGISTRATION.FORM_NAME</mat-label>
                    <input matInput placeholder="{{ 'REGISTRATION.FORM_NAME' | translate }}" formControlName="name" required>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label translate>REGISTRATION.FORM_EMAIL</mat-label>
                    <input matInput placeholder="{{ 'REGISTRATION.FORM_EMAIL' | translate }}" formControlName="email" required>
                    <mat-error *ngIf="registrationFormGroup.controls.email.errors" translate>REGISTRATION.INVALID_MAIL_FORMAT</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label translate>REGISTRATION.FORM_PASSWORD</mat-label>
                    <input (keypress)="letterOnly($event)" type="password" matInput placeholder="{{ 'REGISTRATION.FORM_PASSWORD' | translate }}" formControlName="password" required>
                    <mat-error *ngIf="registrationFormGroup.controls.password.invalid" translate>REGISTRATION.MINIMUM_PASSWORD_LENGTH_ERROR</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label translate>REGISTRATION.FORM_CONFIRM_PASSWORD</mat-label>
                    <input (keypress)="letterOnly($event)" type="password" matInput placeholder="{{ 'REGISTRATION.FORM_CONFIRM_PASSWORD' | translate }}" formControlName="cpassword" required>
                    <mat-error *ngIf="registrationFormGroup.controls.cpassword.invalid" translate>REGISTRATION.MINIMUM_PASSWORD_LENGTH_ERROR</mat-error>
                </mat-form-field>
                <div *ngIf="formErrorMassage!==''" style="color: red;" [innerHTML]="formErrorMassage">
                </div>
                <div>
                    <button [disabled]='registrationFormGroup.status == "VALID" && step==3' (click)="submitForm()" mat-raised-button color="accent" >{{ 'REGISTRATION.FORM_COMPLETE' | translate }}</button>
                </div>
              </form>
            </div>
        </div>
    </div>
</mat-card>
