import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, Event } from '@angular/router';
import { LoginService } from './login.service';
import { ApiService }  from './api.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { InboxDialog } from './dashboard/dashboard.component'
import { NavigationStart, NavigationError, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'web-client';
  notification_no = 0;
  param = {value: 'world'};

  constructor(public dialog: MatDialog,private translate: TranslateService, public loginService: LoginService, private router: Router, private apiService: ApiService) {
    let lang = localStorage.getItem('language');
    if(lang){
      translate.use(lang);
    }else{
      translate.setDefaultLang('en');
      translate.use('en');
      localStorage.setItem('language','en');
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          //do something on start activity
      }

      if (event instanceof NavigationError) {
          // Handle error
          console.error(event.error);
      }

      if (event instanceof NavigationEnd) {
        if(event.url == '/dashboard'){
          this.apiService.getNewestNotifications().subscribe(res=>{
            if(res.result && res.notifications.length){
              this.notification_no = res.notifications.length;
              const dialogRef = this.dialog.open(InboxDialog, {
                width: '80%',
                maxWidth: '550px',
                minWidth: '300px',
                // height: '80%',
                data: {
                  notifications : res.notifications,
                }
              });
          
              dialogRef.afterClosed().subscribe(result => {
                // if(typeof(result)=='undefined'){
                //   this.reload()
                // }
              });
            }
          })
        }
          //do something on end activity
      }
  });
  }

  changeLanguage(){
    if(this.translate.currentLang == 'en'){
      this.translate.use('zh');
      localStorage.setItem('language','zh');
    }else{
      this.translate.use('en');
      localStorage.setItem('language','en');
    }
  }

  dashboard():void {
    this.router.navigate(['dashboard'])
  }

  profile(): void { 
    this.router.navigate(['profile'])
  }
  
  routeList(): void {
    this.router.navigate(['route-list'])
  }

  inbox(): void {
    this.router.navigate(['inbox'])
  }

  logout(): void {
    this.loginService.logout();
    this.router.navigate(['']);
  }
}
