import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { RecaptchaModule } from "ng-recaptcha";

import { AuthGuard } from './services/authguard.service';
import { AuthInterceptor } from './auth-interceptor.interceptor'

//components
import { ContentBlockComponent } from './content-block/content-block.component';

//pages
import { LogInPageComponent } from './log-in-page/log-in-page.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';

//materials
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { DashboardComponent, InboxDialog, ContactUsDialog } from './dashboard/dashboard.component';
import { ForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { StudentComponent } from './student/student.component';
import { InboxComponent } from './inbox/inbox.component';
import { PaymentComponent } from './payment/payment.component';


import { ImageUploaderModule } from 'ngx-image-uploader-next';
import { JwtImagePipe } from './jwt-image.pipe';
import { ProfileComponent } from './profile/profile.component';
import { RouteListComponent } from './route-list/route-list.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
Bugsnag.start({ apiKey: '1edbc9f64b72965a2cbbf5c50f05aacf' })
export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}
@NgModule({
  declarations: [
    AppComponent,
    ContentBlockComponent,
    LogInPageComponent,
    RegistrationPageComponent,
    DashboardComponent,
    InboxDialog,
    ContactUsDialog,
    ForgotPasswordPageComponent,
    StudentComponent,
    InboxComponent,
    PaymentComponent,
    JwtImagePipe,
    ProfileComponent,
    RouteListComponent,
    PaymentHistoryComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    RecaptchaModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatInputModule,
    MatButtonModule,
    MatStepperModule,
    MatTabsModule,
    MatSelectModule,
    MatMenuModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatBadgeModule,
    ImageUploaderModule
  ],
  // providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },AuthGuard],
  providers: [ { provide: ErrorHandler, useFactory: errorHandlerFactory },{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
