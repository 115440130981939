import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../environments/environment';

@Pipe({
  name: 'jwtImage'
})
export class JwtImagePipe implements PipeTransform {

  constructor(
    private http: HttpClient,
    // private auth: AuthService, // our service that provides us with the authorization token
  ) {}

  async transform(src: string): Promise<string> {
    const token = localStorage.getItem('id_token'); 
    const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});
    try {
      const imageBlob = await this.http.get(environment.api + src, {headers, responseType: 'blob'}).toPromise();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });
    } catch {
      console.log("error")
      return "asddsa";
    }
  }

}
