<!-- Desktop -->
<div fxShow fxHide.lt-sm fxLayout="column" class="whiteboard-mid">
    <div fxFlex fxLayout="column" >
        <mat-card class="block" fxLayout="column">
            <div class="block-top">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">mail</mat-icon>
                </div>
                <div class="componenttitle" translate>
                    INBOX.INBOX
                </div>
            </div>    
            <div style="margin:-35px 0 15px auto;">
                <mat-button-toggle-group [(ngModel)]="seenFilter" style="border-radius:15px;">
                    <mat-button-toggle class="poppinsR" value="Unseen">{{'INBOX.UNSEEN'|translate}}</mat-button-toggle>
                    <mat-button-toggle class="poppinsR" value="All">{{'INBOX.ALL'|translate}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div *ngFor="let notification of notifications" >
                <div *ngIf="((seenFilter=='All')||(seenFilter=='Unseen' && notification.MemberNotification.status=='Unseen')) && ((importantChip && notification.type=='Important')||(newsChip && notification.type=='News')||(systemChip && notification.type=='System'))" fxLayout="column" style="border-bottom: 1px gray solid; padding-top:20px; padding-bottom: 20px;">
                    <div>
                        <span style="float:left; font-family:poppinsB">{{moment(notification.date).format('DD/MM/YYYY')}}</span>
                    </div>       
                    <div *ngIf="!notification.showMore" class="content-box" [innerHTML]="translate.currentLang=='en'?notification.content:notification.content_chinese">
                    </div>
                    <div *ngIf="notification.showMore" class="content-box-show-more" [innerHTML]="translate.currentLang=='en'?notification.content:notification.content_chinese">
                    </div>
                    <div (click)="notification.showMore=!notification.showMore" *ngIf="!notification.showMore" class="show-button">{{'INBOX_POP.SHOW_MORE'|translate}}</div>
                    <div (click)="notification.showMore=!notification.showMore" *ngIf="notification.showMore" class="show-button">{{'INBOX_POP.SHOW_LESS'|translate}}</div>
                    <button *ngIf="notification.MemberNotification.status=='Unseen'" fxFlex mat-raised-button color="accent" class="inbox_btn" (click)="markAsSeen(notification.id)">
                        {{'INBOX.MARK_AS_SEEN'|translate}}
                    </button>
                    <button *ngIf="notification.MemberNotification.status=='Seen'" fxFlex mat-stroked-button color="accent" class="inbox_btn" (click)="markAsUnseen(notification.id)">
                        {{'INBOX.MARK_AS_UNSEEN'|translate}}
                    </button>
                </div>
            </div>
        </mat-card>
    </div>
</div>
<!-- Mobile -->
<div fxHide fxShow.lt-sm fxLayout="column">
    <div fxFlex fxLayout="column" >
        <mat-card class="block" fxLayout="column">
            <div class="block-top">
                <div class="block-label">
                    <mat-icon class="icon" aria-hidden="false" aria-label="Example home icon">mail</mat-icon>
                </div>
                <div class="componenttitle" translate>
                    INBOX.INBOX
                </div>
            </div>
            <div style="margin:-35px 0 15px auto;">
                <mat-button-toggle-group [(ngModel)]="seenFilter" style="border-radius:15px;">
                    <mat-button-toggle class="poppinsR" value="Unseen">{{'INBOX.UNSEEN'|translate}}</mat-button-toggle>
                    <mat-button-toggle class="poppinsR" value="All">{{'INBOX.ALL'|translate}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div *ngFor="let notification of notifications" >
                <div *ngIf="((seenFilter=='All')||(seenFilter=='Unseen' && notification.MemberNotification.status=='Unseen')) && ((importantChip && notification.type=='Important')||(newsChip && notification.type=='News')||(systemChip && notification.type=='System'))" fxLayout="column" style="border-bottom: 1px gray solid; padding-top:20px; padding-bottom: 20px;">
                    <div>
                        <span style="float:left; font-family:poppinsR">{{moment(notification.date).format('DD/MM/YYYY')}}</span>
                    </div>
                    <span style="font-family: poppinsR;word-wrap: break-word;">{{translate.currentLang=='en'?notification.content:notification.content_chinese}}</span>
                    <button *ngIf="notification.MemberNotification.status=='Unseen'" fxFlex mat-raised-button color="accent" class="inbox_btn" (click)="markAsSeen(notification.id)">
                        {{'INBOX.MARK_AS_SEEN'|translate}}
                    </button>
                    <button *ngIf="notification.MemberNotification.status=='Seen'" fxFlex mat-stroked-button color="accent" class="inbox_btn" (click)="markAsUnseen(notification.id)">
                        {{'INBOX.MARK_AS_UNSEEN'|translate}}
                    </button>
                </div>
            </div>
        </mat-card>
    </div>
</div>