import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import * as moment from "moment";

export interface Auth {
  status: boolean;
  token: string;
  expiresIn: string;
  err: string;
  language_preference: string;
}

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  constructor(private http: HttpClient) {
  }
  login(username: string, password: string): Observable<Auth> {
    return this.http.post<Auth>(environment.apiEndpoint + 'login', {username: username, password: password})
      .pipe(
        tap(_ => console.log('login'))
      );
  }

  setSession(token: string, expiresAt: string) {
    localStorage.setItem('id_token', token); 
    localStorage.setItem("expires_at", expiresAt);
  }          

  logout() {
      localStorage.removeItem("id_token");
      localStorage.removeItem("expires_at");
  }
  
  public isLoggedIn() {
    return localStorage.getItem("id_token")?true: false;
  }

  getExpiration() {
      let expiration = localStorage.getItem("expires_at");
      let expiresAt = "";
      if(expiration){
        expiresAt = JSON.parse(expiration);
      }
      return moment(expiresAt);
  }   
}
